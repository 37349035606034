import { PersistentId } from '~/framework/typeAliases';

// FIXME UpperCamelCase やめたい
// https://github.com/FanfareInc/rin/issues/737

/**
 * 未定義状態の PersistentId
 * つまりまだ永続化されていない ID の事
 */
export const UndefinedPersistentId: PersistentId = '';

/**
 * DB 上あり得ない PersistentId
 */
export const ImpossiblePersistentId: PersistentId = '-1';

/**
 * 受注チェックをする際のまだ存在していない新規受注用の ID
 */
export const OrderAcceptanceCheckId: PersistentId = '0';

/**
 * 一週間に何日あるか
 */
export const DaysAWeek: number = 7;

/**
 * 24時間に何秒あるか
 */
export const DaySeconds: number = 86400;

/**
 * 指定可能な時間の上限
 * 0:00 ~ 35:59 までを許容する
 */
export const HardLimitTime: number = 129600;

/**
 * Vuetify に定義されている色
 * その他あれば適宜追加
 */
export enum VuetifyColors {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Primary = 'primary',
  BluishGray40 = 'bluish-gray_40',
  BluishGray60 = 'bluish-gray_60',
}

/**
 * Nuxt 上のパス
 * どこかに定数にしておきリファクタリングした時に一緒に変更しないとバグるため置いておく
 *
 * TODO あとでもうちょいまともにする
 */
export const Path = {
  login: '/login',
  masters: {
    index: '/masters',
    driver: '/masters/driver',
    'driver-attendance': '/masters/driver#driver-attendances',
    'disposal-site': '/masters/disposal-site',
    'disposal-site-attendance': '/masters/disposal-site#attendances',
    'collectable-period-template': '/masters/collectable-period-template',
    container: '/masters/container',
    'office-setting': '/masters/office-setting',
    'generation-site': '/masters/generation-site',
    car: '/masters/car',
    'base-site': '/masters/base-site',
    execution: {
      index: '/execution',
    },
    'waste-type': '/masters/waste-type',
  },
  schedule: {
    index: '/schedule',
    orders: {
      index: '/schedule/orders',
    },
  },
  execution: {
    index: '/execution',
    'packing-placements': '/execution/packing-placements',
  },
};
