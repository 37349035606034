import { DriverSchedule } from './driverSchedule';
import { Maybe } from '~/framework/typeAliases';
import {
  RawInconsistencyJsonObject,
  RawScheduleInfeasibilityJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { convertNullToUndefined } from '~/framework/property';
import { ScheduleResponseJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';
import { ScheduleRequest } from '~/graphql/custom-scalars/scheduleRequestJsonObjectTypes';

export class ScheduleResponse {
  driverSchedules: DriverSchedule[];
  scheduleIdentifier: string;
  scheduleVersion: string;
  infeasibilities?: Maybe<RawScheduleInfeasibilityJsonObject[]>;
  inconsistencies?: Maybe<RawInconsistencyJsonObject[]>;
  notAssignedOrderIds: string[];
  notAssignedDriverIds: string[];
  constructor(scheduleResponse: ScheduleResponseJsonObject) {
    const converted = convertNullToUndefined(scheduleResponse) as ScheduleResponseJsonObject;
    this.driverSchedules = converted.driver_schedules.map((driverSchedule) => {
      return new DriverSchedule(driverSchedule);
    });
    this.scheduleIdentifier = converted.schedule_identifier;
    this.scheduleVersion = converted.schedule_version;
    this.infeasibilities = converted.infeasibilities;
    this.inconsistencies = converted.inconsistencies;
    this.notAssignedOrderIds = converted.not_assigned_order_ids.map((id) => id.toPseudoId().value);
    this.notAssignedDriverIds = converted.not_assigned_driver_ids.map((id) => id.toPseudoId().value);

    // DriverSchedules内の全てのRouteに対して全体のRouteの数から引いていったindex値を設定する
    let routeIndex = 0;
    for (const driverSchedule of this.driverSchedules) {
      for (const route of driverSchedule.routes) {
        route.scheduleRouteIndex = routeIndex;
        routeIndex++;
      }
    }
  }

  // getSchedulesByScheduleRequestとrevertScheduleで使うためのScheduleRequestを返す
  getScheduleRequest(): ScheduleRequest {
    // APIリクエスト前のデータ整形処理
    let driverSchedules = this.driverSchedules.map((driverSchedule) => {
      return driverSchedule.getScheduleRequestDriverSchedule();
    });
    // 受注がないルートを削除
    driverSchedules = driverSchedules.map((driverSchedule) => {
      driverSchedule.routes = driverSchedule.routes.filter((route) => route.order_assignments.length > 0);
      return driverSchedule;
    });
    // ルートがないドライバーを削除
    driverSchedules = driverSchedules.filter((driverSchedule) => driverSchedule.routes.length > 0);

    // インデックスの貼り直し
    driverSchedules.forEach((driver) => {
      driver.routes.forEach((route, routeIndex) => {
        route.route_index = routeIndex;
        route.order_assignments.forEach((orderAssignment, orderAssignmentIndex) => {
          orderAssignment.order_assignment_index = orderAssignmentIndex;
        });
      });
    });

    return {
      driver_schedules: driverSchedules,
      schedule_identifier: this.scheduleIdentifier,
      schedule_version: this.scheduleVersion,
    };
  }

  // createScheduleで使うためのScheduleResponseJsonObjectを返す
  getScheduleResponseJsonObject(): ScheduleResponseJsonObject {
    return {
      driver_schedules: this.driverSchedules.map((driverSchedule) => {
        return driverSchedule.getDriverScheduleJsonObject();
      }),
      schedule_identifier: this.scheduleIdentifier,
      schedule_version: this.scheduleVersion,
      infeasibilities: this.infeasibilities,
      inconsistencies: this.inconsistencies,
      not_assigned_order_ids: this.notAssignedOrderIds,
      not_assigned_driver_ids: this.notAssignedDriverIds,
    };
  }
}
