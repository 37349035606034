import { Disposal } from './disposal';
import { OrderAssignment } from './orderAssignment';
import { Period } from './period';
import { Warning } from './warning';
import { ScheduleRequestRoute } from '~/graphql/custom-scalars/scheduleRequestJsonObjectTypes';
import { Maybe } from '~/framework/typeAliases';
import { RouteJsonObject, DriverType } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class Route {
  // 元データJSONベース
  routeIndex: number;
  mainCarId: Maybe<string>;
  carIds: Maybe<string[]>;
  driverType: DriverType;
  operatorId: string;
  helperIds: string[];
  isOriginalRoute: boolean;
  fixedTime: Maybe<Period>;
  orderAssignments: OrderAssignment[];
  disposals: Disposal[];
  warnings: Warning[];

  constructor(routeJsonObject: RouteJsonObject) {
    this.routeIndex = routeJsonObject.route_index;
    this.mainCarId = routeJsonObject.main_car_id?.toPseudoId().value;
    this.mainCarPseudoId = routeJsonObject.main_car_id;
    this.carIds = routeJsonObject.car_ids?.map((id) => id.toPseudoId().value);
    this.carPseudoIds = routeJsonObject.car_ids;
    this.driverType = routeJsonObject.driver_type;
    this.operatorId = routeJsonObject.operator_id.toPseudoId().value;
    this.operatorPseudoId = routeJsonObject.operator_id;
    this.helperIds = routeJsonObject.helper_ids?.map((id) => id.toPseudoId().value);
    this.helperPseudoIds = routeJsonObject.helper_ids;
    this.isOriginalRoute = routeJsonObject.is_original_route;
    this.fixedTime = new Period(routeJsonObject.fixed_time);
    this.orderAssignments = routeJsonObject.order_assignments.map((orderAssignment) => {
      return new OrderAssignment(orderAssignment);
    });
    this.disposals = routeJsonObject.disposals.map((disposal) => {
      return new Disposal(disposal);
    });
    this.warnings = routeJsonObject.warnings.map((warning) => {
      return new Warning(warning);
    });
  }

  // Rin側で必要な項目
  mainCarPseudoId: Maybe<string>;
  carPseudoIds: Maybe<string[]>;
  operatorPseudoId: string;
  helperPseudoIds: string[];
  // 配車表全体から見たRouteのindex
  scheduleRouteIndex: Maybe<number>;

  isDraggable(): boolean {
    return !this.orderAssignments.some((orderAssignment) => orderAssignment.isFixedAssignment);
  }

  getScheduleRequestRoute(): ScheduleRequestRoute {
    return {
      route_index: this.routeIndex,
      driver_type: this.driverType,
      fixed_time: this.fixedTime?.getScheduleRequestPeriod(),
      order_assignments: this.orderAssignments.map((orderAssignment) =>
        orderAssignment.getScheduleRequestOrderAssignment()
      ),
    };
  }

  getStartTime(): number {
    // OrderAssignmentがない場合は0を返す。
    if (this.orderAssignments.length === 0) {
      return 0;
    }
    // fixedTimeがあればそのStartを返す
    if (this.fixedTime && this.fixedTime.startTime) {
      return this.fixedTime.startTime;
    }
    if (this.orderAssignments[0].events.length === 0) {
      throw new Error('Route has no events');
    }
    // 先頭のOrderAssignmentの最初のEventのTimeを返す
    return this.orderAssignments[0].events[0].time;
  }

  getEndTime(): number {
    // OrderAssignmentがない場合は0を返す。
    if (this.orderAssignments.length === 0) {
      return 0;
    }
    // fixedTimeがあればそのEndを返す
    if (this.fixedTime && this.fixedTime.endTime) {
      return this.fixedTime.endTime;
    }
    if (this.orderAssignments[this.orderAssignments.length - 1].events.length === 0) {
      throw new Error('Route has no events');
    }
    // 最後のOrderAssignmentの最後のEventのTimeを返す
    return this.orderAssignments[this.orderAssignments.length - 1].events[
      this.orderAssignments[this.orderAssignments.length - 1].events.length - 1
    ].time;
  }

  getRouteJsonObject(): RouteJsonObject {
    return {
      route_index: this.routeIndex,
      main_car_id: this.mainCarPseudoId,
      car_ids: this.carPseudoIds,
      driver_type: this.driverType,
      operator_id: this.operatorPseudoId,
      helper_ids: this.helperPseudoIds,
      is_original_route: this.isOriginalRoute,
      fixed_time: this.fixedTime?.getPeriodJsonObject(),
      order_assignments: this.orderAssignments.map((orderAssignment) => orderAssignment.getOrderAssignmentJsonObject()),
      disposals: this.disposals.map((disposal) => disposal.getDisposalJsonObject()),
      warnings: this.warnings.map((warning) => warning.getWarningJsonObject()),
    };
  }

  /**
   * ルートの全ての受注が固定されているかどうか
   * @return {boolean}
   */
  isAllOrderAssignmentsFixed(): boolean {
    return this.orderAssignments.every((orderAssignment) => orderAssignment.isFixedAssignment);
  }

  /**
   * ルートの最後の受注かどうか
   */
  isLastOfRoute(orderAssignment: OrderAssignment): boolean {
    return orderAssignment.orderAssignmentIndex === this.orderAssignments.length - 1;
  }
}
