import { User, UsersCondition } from '~/graphql/graphQLServerApi';
import { Maybe } from '~/framework/typeAliases';
import { IConnection } from '~/framework/server-api/connection';

export const user$usersSymbol = Symbol('user$usersSymbol');
export const user$getByIdsSymbol = Symbol('user$getByIdsSymbol');

export type UserApiTypes = {
  [user$usersSymbol]: IUsers;
  [user$getByIdsSymbol]: IGetByIds;
};

export interface IUsers {
  search(first: number, after: Maybe<string>, condition: UsersCondition): Promise<IConnection<IUserData>>;
}

export interface IGetByIds {
  getByIds(ids: string[]): Promise<IUserData[]>;
}

export type IUserData = User;
