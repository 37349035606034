import { PersistentId } from '~/framework/typeAliases';
import { ImpossiblePersistentId, UndefinedPersistentId } from '~/framework/constants';
import { PseudoDriverAttendanceTemplateName } from '~/framework/view-models/driverAttendanceTemplate';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { CarEntity as ICarEntity } from '~/framework/domain/masters/car/carEntity';

// 各種勤怠入力の選択肢

/**
 * 勤怠テンプレ
 */
export interface IDriverAttendanceTemplateItem {
  value: PersistentId;
  text: string;
  disabled: boolean;
}

/**
 * 車
 */
export interface ICarItem {
  value: PersistentId;
  text: string;
  enabledOn: Date;
}

/**
 * テンプレ名からテンプレが見つからなかった場合に利用する選択肢
 */
export const PseudoDriverAttendanceTemplateItem: IDriverAttendanceTemplateItem = {
  text: PseudoDriverAttendanceTemplateName,
  value: ImpossiblePersistentId,
  disabled: true,
};

export const generateWorkDayDriverAttendanceTemplateItems = (
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[]
): IDriverAttendanceTemplateItem[] => {
  const items: IDriverAttendanceTemplateItem[] = [];
  items.push({
    text: '勤務無',
    value: UndefinedPersistentId,
    disabled: false,
  });
  for (const template of driverAttendanceTemplates) {
    items.push({
      text: template.name,
      value: template.persistentId,
      disabled: false,
    });
  }
  return items;
};

export const generateHolidayDriverAttendanceTemplateItems = (
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[]
): IDriverAttendanceTemplateItem[] => {
  const items: IDriverAttendanceTemplateItem[] = [];
  items.push({
    text: '休み',
    value: UndefinedPersistentId,
    disabled: false,
  });
  for (const template of driverAttendanceTemplates) {
    items.push({
      text: template.name,
      value: template.persistentId,
      disabled: false,
    });
  }
  return items;
};

export const generateCarItems = (cars: ICarEntity[]): ICarItem[] => {
  const items: ICarItem[] = [];
  for (const car of cars) {
    items.push({
      text: `${car.carType.name} ${car.name}`,
      value: car.persistentId,
      enabledOn: car.enabledOn,
    });
  }
  return items;
};
