import { Route } from './route';
import { ScheduleRequestDriverSchedule } from '~/graphql/custom-scalars/scheduleRequestJsonObjectTypes';
import { DriverScheduleJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class DriverSchedule {
  /**
   * ドライバーID。
   */
  driverId: string;

  /**
   * ルート
   */
  routes: Route[];

  /**
   * DriverSchedule
   * @param {DriverScheduleJsonObject} driverSchedule
   */
  constructor(driverSchedule: DriverScheduleJsonObject) {
    this.driverId = driverSchedule.driver_id.toPseudoId().value;
    this.driverPseudoId = driverSchedule.driver_id;
    this.routes = driverSchedule.routes.map((route) => {
      return new Route(route);
    });
  }

  /**
   * ドライバーの擬似ID。
   */
  driverPseudoId: string;

  /**
   * ScheduleRequestDriverSchedule
   * @return {ScheduleRequestDriverSchedule}
   */
  getScheduleRequestDriverSchedule(): ScheduleRequestDriverSchedule {
    return {
      driver_id: this.driverPseudoId,
      routes: this.routes.map((route) => route.getScheduleRequestRoute()),
    };
  }

  /**
   * DriverSchedule JSONObject
   * @return {DriverScheduleJsonObject}
   */
  getDriverScheduleJsonObject(): DriverScheduleJsonObject {
    return {
      driver_id: this.driverPseudoId,
      routes: this.routes.map((route) => route.getRouteJsonObject()),
    };
  }

  // ドライバーの全てのルートが固定されているかどうか
  isAllRoutesFixed(): boolean {
    return this.routes.every((route) => route.isAllOrderAssignmentsFixed());
  }
}
