import { Maybe, PersistentId } from '~/framework/typeAliases';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { holidayRuleSymbol } from '~/framework/application/masters/holiday-rule/holidayRuleApplicationService';
import { officeSettingSymbol } from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { orderGroupSymbol } from '~/framework/application/masters/order-group/orderGroupApplicationService';
import { disposalSiteSymbol } from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import { wasteTypeSymbol } from '~/framework/application/masters/waste-type/wasteTypeApplicationService';
import { containerTypeSymbol } from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { containerTaskSymbol } from '~/framework/application/masters/container-type/containerTaskApplicationService';
import { collectablePeriodTemplateSymbol } from '~/framework/application/masters/collectable-period-template/collectablePeriodTemplateApplicationService';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { carSymbol } from '~/framework/application/masters/car/carApplicationService';
import { packingStyleSymbol } from '~/framework/application/masters/packingStyleApplicationService';
import { SystemContext } from '~/framework/systemContext';
import { driverSymbol } from '~/framework/application/masters/driver/driverApplicationService';
import { taskTypeSymbol } from '~/framework/application/masters/task-type/taskTypeApplicationService';
import { driverAttendanceSymbol } from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { checkItemServiceSymbol } from '~/framework/application/masters/check-item/checkItemApplicationService';
import { DisposalSiteAttendanceEntity } from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceEntity';
import { disposalSiteAttendanceServiceSymbol } from '~/framework/application/masters/disposal-site-attendance/disposalSiteAttendanceApplicationService';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { generationSiteSymbol } from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { ids } from '~/framework/core/entity';

export interface IFormData {
  cars: CarEntity[];
  carTypes: CarTypeEntity[];
  collectablePeriodTemplates: CollectablePeriodTemplateEntity[];
  wasteTypes: WasteTypeEntity[];
  orderGroups: OrderGroupEntity[];
  drivers: DriverEntity[];
  disposalSites: DisposalSiteEntity[];
  disposalSiteAttendances: DisposalSiteAttendanceEntity[];
  client: Maybe<ClientEntity>;
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>;
  containerTypes: ContainerTypeEntity[];
  generationSite: Maybe<GenerationSiteEntity>;
  officeSetting: OfficeSettingEntity;
  packingStyles: PackingStyleEntity[];
  taskTypes: TaskTypeEntity[];
  checkItems: CheckItemEntity[];
  holidayRule: HolidayRuleEntity;
}

export async function getOrderFormData(
  context: SystemContext,
  date: Maybe<Date>,
  clientId: Maybe<PersistentId>,
  generationSiteId: Maybe<PersistentId>,
  driverIds: PersistentId[]
): Promise<IFormData> {
  const officeSettingApplicationService = context.applications.get(officeSettingSymbol);
  const orderGroupApplicationService = context.applications.get(orderGroupSymbol);
  const wasteTypeApplicationService = context.applications.get(wasteTypeSymbol);
  const containerTypeApplicationService = context.applications.get(containerTypeSymbol);
  const containerTypeTaskTypeApplicationService = context.applications.get(containerTaskSymbol);
  const collectablePeriodTemplateApplicationService = context.applications.get(collectablePeriodTemplateSymbol);
  const carTypeApplicationService = context.applications.get(carTypeSymbol);
  const carApplicationService = context.applications.get(carSymbol);
  const holidayRuleApplicationService = context.applications.get(holidayRuleSymbol);
  const clientApplicationService = context.applications.get(clientSymbol);
  const packingStyleApplicationService = context.applications.get(packingStyleSymbol);
  const disposalSiteApplicationService = context.applications.get(disposalSiteSymbol);
  const driverApplicationService = context.applications.get(driverSymbol);
  const generationSiteApplicationService = context.applications.get(generationSiteSymbol);
  const driverAttendanceApplicationService = context.applications.get(driverAttendanceSymbol);
  const taskTypeApplicationService = context.applications.get(taskTypeSymbol);
  const checkItemApplicationService = context.applications.get(checkItemServiceSymbol);
  const disposalSiteAttendanceApplicationService = context.applications.get(disposalSiteAttendanceServiceSymbol);

  const p1 = Promise.all([
    officeSettingApplicationService.get(),
    orderGroupApplicationService.getAll(),
    wasteTypeApplicationService.getAll(),
    containerTypeApplicationService.getAll(),
    containerTypeTaskTypeApplicationService.getAll(),
    collectablePeriodTemplateApplicationService.getAll(),
    carTypeApplicationService.getAll(),
    carApplicationService.getAll(),
    holidayRuleApplicationService.get(),
    packingStyleApplicationService.getAll(),
  ]);

  const driverAttendanceJobs =
    date !== undefined && driverIds.length !== 0
      ? driverIds.map((driverId) => driverAttendanceApplicationService.getListOfDriver(driverId, date, date))
      : undefined;

  const p2 = Promise.all([
    clientId ? clientApplicationService.getById(clientId) : Promise.resolve(undefined),
    disposalSiteApplicationService.getAll(),
    date !== undefined ? disposalSiteAttendanceApplicationService.getByDateRange([], date, date) : Promise.resolve([]),
    driverApplicationService.getAll(),
    generationSiteId ? generationSiteApplicationService.getById(generationSiteId) : Promise.resolve(undefined),
    driverAttendanceJobs ? Promise.all(driverAttendanceJobs) : Promise.resolve(undefined),
    taskTypeApplicationService.getAll(),
    checkItemApplicationService.getAll(),
  ]);

  const [
    officeSetting,
    orderGroups,
    wasteTypes,
    containerTypes,
    containerTypeTaskTypes,
    collectablePeriodTemplates,
    carTypes,
    cars,
    holidayRule,
    packingStyles,
  ] = await p1;

  const [
    client,
    disposalSites,
    disposalSiteAttendances,
    drivers,
    generationSite,
    assignableDriverAttendances,
    taskTypes,
    checkItems,
  ] = await p2;

  if (driverIds) {
    const allActiveDriverIdSet = new Set(ids(drivers));
    const deletedDriverids = driverIds.filter((driverId) => !allActiveDriverIdSet.has(driverId));
    if (deletedDriverids) {
      const deletedDrivers = await driverApplicationService.getByIds(deletedDriverids);
      drivers.concat(deletedDrivers);
    }
  }

  return {
    officeSetting,
    client,
    generationSite,
    orderGroups,
    disposalSites,
    disposalSiteAttendances,
    wasteTypes,
    containerTypes,
    containerTypeTaskTypes,
    collectablePeriodTemplates,
    drivers,
    carTypes,
    cars,
    assignableDriverAttendances: assignableDriverAttendances?.flat(),
    holidayRule,
    packingStyles,
    taskTypes,
    checkItems,
  };
}
