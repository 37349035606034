import { Maybe, PersistentId } from '~/framework/typeAliases';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { UsersCondition } from '~/framework/server-api/typeAliases';
import { IList, ISearch } from '~/framework/application/masters/user/list';
import { IGetByIds, IUsers, user$getByIdsSymbol, user$usersSymbol } from '~/framework/server-api/masters/user';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';

export const userSymbol = Symbol('userSymbol');

export class UserApplicationService implements ISearch {
  serverApis: ServerApiManager;

  constructor(serverApis: ServerApiManager) {
    this.serverApis = serverApis;
  }

  async getByIds(ids: string[]): Promise<UserEntity[]> {
    const api: IGetByIds = this.serverApis.get(user$getByIdsSymbol);
    const result = await api.getByIds(ids);
    return result.map((data) => {
      return new UserEntity(data);
    });
  }

  async getById(id: PersistentId): Promise<UserEntity> {
    const api: IGetByIds = this.serverApis.get(user$getByIdsSymbol);
    const [user] = await api.getByIds([id]);
    return new UserEntity(user);
  }

  async search(first: number, after: Maybe<string>, condition: UsersCondition): Promise<IList> {
    const api: IUsers = this.serverApis.get(user$usersSymbol);
    const result = await api.search(first, after, condition);
    return {
      pageInfo: result.pageInfo,
      items: result.edges.map(({ node, cursor }) => {
        return {
          entity: new UserEntity(node),
          cursor,
        };
      }),
    };
  }
}
