import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Maybe, PersistentId, WithoutId } from '~/framework/typeAliases';
import * as List from '~/framework/application/masters/generation-site/list';
import {
  generationSite$getByKeywordsSymbol,
  ICondition,
  generationSite$createSymbol,
  ICreateGenerationSiteData,
  generationSite$updateSymbol,
  IUpdateGenerationSiteData,
  generationSite$getByIdsSymbol,
} from '~/framework/server-api/masters/generationSite';
import * as Selection from '~/framework/application/masters/generation-site/selection';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';
import { ids } from '~/framework/core/entity';

export const generationSiteSymbol = Symbol('generationSiteSymbol');

export class GenerationSiteApplicationService implements List.ISearch, Selection.ISearchSelection {
  private readonly serverApis: ServerApiManager;
  private generationSiteMapper: GenerationSiteMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.generationSiteMapper = new GenerationSiteMapper(
      store.masters.generationSite,
      store.masters.client,
      store.masters.user,
      store.masters.disposalSite,
      store.masters.collectablePeriodTemplate,
      store.masters.car,
      store.masters.baseSite,
      store.masters.driver,
      store.masters.driverAttendanceTemplate
    );
  }

  async create(data: ICreateGenerationSiteData): Promise<GenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSite$createApi = this.serverApis.get(generationSite$createSymbol);

    const [createResult] = await generationSite$createApi.create([data]);
    const [generationSiteData] = await generationSite$getByIds.getByIds([createResult.id]);
    const entity = this.generationSiteMapper.mapSingle(generationSiteData);
    return entity;
  }

  async update(data: IUpdateGenerationSiteData): Promise<GenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSite$updateApi = this.serverApis.get(generationSite$updateSymbol);

    const [updateResult] = await generationSite$updateApi.update([data]);
    const [generationSiteData] = await generationSite$getByIds.getByIds([updateResult]);
    const entity = this.generationSiteMapper.mapSingle(generationSiteData);
    return entity;
  }

  async delete(id: PersistentId): Promise<GenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const [data] = await generationSite$getByIds.getByIds([id]);
    const updateData: IUpdateGenerationSiteData = {
      id: data.id,
      clientId: data.client.id,
      name: data.name,
      nameRuby: data.nameRuby,
      zipCode: data.zipCode,
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      address4: data.address4,
      latitude: data.latitude,
      longitude: data.longitude,
      bannedDriverIds: ids(data.bannedDrivers),
      defaultAssignedDriverId: data.defaultAssignedDriver ? data.defaultAssignedDriver.id : undefined,
      defaultAssignedDisposalSiteId: data.defaultAssignedDisposalSite?.id,
      defaultAssignableCarIds: ids(data.defaultAssignableCars),
      defaultAssignableCarTypeIds: ids(data.defaultAssignableCarTypes),
      defaultAvoidHighways: data.defaultAvoidHighways,
      defaultDurationAtEntrance: data.defaultDurationAtEntrance,
      restPeriodStart: data.restPeriodStart,
      restPeriodEnd: data.restPeriodEnd,
      note: data.note,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      isAddressComplete: data.isAddressComplete,
      defaultCollectablePeriodTemplateId: data.defaultCollectablePeriodTemplate
        ? data.defaultCollectablePeriodTemplate.id
        : undefined,
      defaultCollectablePeriodStart: data.defaultCollectablePeriodStart,
      defaultCollectablePeriodEnd: data.defaultCollectablePeriodEnd,
      defaultPreloadStatus: data.defaultPreloadStatus,
      defaultRouteCollectionAllowed: data.defaultRouteCollectionAllowed,
      defaultIsFixedArrivalTimeReportNeeded: data.defaultIsFixedArrivalTimeReportNeeded,
      defaultMarginTypeOfFixedArrivalTime: data.defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime: data.defaultMarginOfFixedArrivalTime,
      noteForOffice: data.noteForOffice,
      status: SoftDeleteStatus.Deleted, // NOTE これが大事
    };
    return await this.update(updateData);
  }
  // endregion

  // region List
  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<List.IList> {
    const generationSite$getByKeywordsApi = this.serverApis.get(generationSite$getByKeywordsSymbol);
    const result = await generationSite$getByKeywordsApi.getByKeywords(first, after, condition);
    const data = result.edges.map((edge) => edge.node);
    const entities = this.generationSiteMapper.map(data);
    const output: List.IList = {
      pageInfo: result.pageInfo,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region Selection
  async searchSelection(first: number, after: Maybe<string>, condition: ICondition): Promise<Selection.IList> {
    const generationSite$getByKeywordsApi = this.serverApis.get(generationSite$getByKeywordsSymbol);
    const result = await generationSite$getByKeywordsApi.getByKeywords(first, after, condition);
    const entities = this.generationSiteMapper.map(
      result.edges.map(({ node }) => node)
    ) as unknown as GenerationSiteEntity[];
    const output: Selection.IList = {
      pageInfo: result.pageInfo,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region Import
  async createMultiple(data: WithoutId<ICreateGenerationSiteData>[]): Promise<GenerationSiteEntity[]> {
    const generationSite$createApi = this.serverApis.get(generationSite$createSymbol);
    const result = await generationSite$createApi.create(data);
    return await this.getByIds(result.map((res) => res.id));
  }

  async updateMultiple(updateData: IUpdateGenerationSiteData[]): Promise<GenerationSiteEntity[]> {
    const generationSite$updateApi = this.serverApis.get(generationSite$updateSymbol);
    const ids = await generationSite$updateApi.update(updateData);
    return await this.getByIds(ids);
  }

  // endregion

  async getByIds(ids: string[]): Promise<GenerationSiteEntity[]> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const data = await generationSite$getByIds.getByIds(ids);
    const entities = this.generationSiteMapper.map(data);
    return entities;
  }

  async getById(id: PersistentId): Promise<GenerationSiteEntity> {
    const generationSite$getByIdsApi = this.serverApis.get(generationSite$getByIdsSymbol);
    const [generationSite] = await generationSite$getByIdsApi.getByIds([id]);
    return this.generationSiteMapper.mapSingle(generationSite);
  }
}
