import {
  ActivateOrderMutation,
  ActivateOrderMutationVariables,
  CancelOrderMutation,
  CancelOrderMutationVariables,
  CreateOrdersMutation,
  CreateOrdersMutationVariables,
  GenerationSiteTasksByIdsQuery,
  GenerationSiteTasksByIdsQueryVariables,
  IrregularTasksByIdsQuery,
  IrregularTasksByIdsQueryVariables,
  LatestOrdersQuery,
  LatestOrdersQueryVariables,
  RoutableOrdersQuery,
  RoutableOrdersQueryVariables,
  OrderDefaultByGenerationSiteIdQuery,
  OrderDefaultByGenerationSiteIdQueryVariables,
  OrdersByDateQuery,
  OrdersByDateQueryVariables,
  OrdersByIdsQuery,
  OrdersByIdsQueryVariables,
  UpdateOrdersMutation,
  UpdateOrdersMutationVariables,
  ValidateOrdersQuery,
  ValidateOrdersQueryVariables,
  PostponeOrdersMutation,
  PostponeOrdersMutationVariables,
  UpdateOrderCheckItemMutation,
  UpdateOrderCheckItemMutationVariables,
  SearchOrdersInput,
  SearchOrdersQuery,
  SearchOrdersQueryVariables,
} from '@/graphql/graphQLServerApi';
import {
  LatestOrders,
  RoutableOrders,
  OrdersByDate,
  OrdersByIds,
  ValidateOrders,
  SearchOrders,
} from '@/../graphql/server-api/queries/order.gql';
import {
  ActivateOrder,
  CancelOrder,
  CreateOrders,
  UpdateOrders,
  PostponeOrders,
  UpdateOrderCheckItem,
} from '@/../graphql/server-api/mutations/order.gql';
import { GenerationSiteTasksByIds } from '@/../graphql/server-api/queries/generation-site-task.gql';
import { IrregularTasksByIds } from '@/../graphql/server-api/queries/irregular-task.gql';
import { OrderDefaultByGenerationSiteId } from '@/../graphql/server-api/queries/order-default.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IOrdersByDateApi, order$ordersByDateSymbol } from '~/framework/server-api/schedule/order/ordersByDate';
import { IOrderData, IResultOrderData } from '~/framework/server-api/schedule/order/order';
import { IOrdersByIdsApi, order$ordersByIdsSymbol } from '~/framework/server-api/schedule/order/ordersByIds';
import {
  ICreateOrderData,
  ICreateOrdersApi,
  order$createOrdersSymbol,
} from '~/framework/server-api/schedule/order/createOrders';
import {
  IUpdateOrderData,
  IUpdateOrdersApi,
  order$updateOrdersSymbol,
} from '~/framework/server-api/schedule/order/updateOrders';
import { ICancelOrderApi, order$cancelOrderSymbol } from '~/framework/server-api/schedule/order/cancelOrder';
import { IActivateOrderApi, order$activateOrderSymbol } from '~/framework/server-api/schedule/order/activateOrder';
import {
  IPostponeData,
  IPostponeOrdersApi,
  PostponeOrderResultTypes,
  order$postponeOrdersSymbol,
} from '~/framework/server-api/schedule/order/postponeOrders';
import {
  generationSiteTask$generationSiteTasksByIds,
  IGenerationSiteTasksByIdsApi,
} from '~/framework/server-api/schedule/order/generation-site-task/generationSiteTasksByIds';
import { IGenerationSiteTaskData } from '~/framework/server-api/schedule/order/generation-site-task/generationSiteTask';
import {
  IIrregularTasksByIds,
  irregularTask$irregularTasksByIdsSymbol,
} from '~/framework/server-api/schedule/order/irregular-task/irregularTasksByIds';
import { IIrregularTaskData } from '~/framework/server-api/schedule/order/irregular-task/irregularTask';
import { IValidateOrdersApi, order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';
import { RawRouteJsonObject, RawScheduleJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import {
  IOrderDefaultByGenerationSiteIdApi,
  orderDefault$orderDefaultByGenerationSiteId,
} from '~/framework/server-api/schedule/order/order-default/orderDefaultByGenerationSiteId';
import { IOrderDefault } from '~/framework/server-api/schedule/order/order-default/orderDefault';
import {
  ILatestOrdersApi,
  ILatestOrdersCondition,
  order$latestOrders,
} from '~/framework/server-api/schedule/order/latestOrders';
import {
  IRoutableOrdersApi,
  IRoutableOrdersCondition,
  order$routableOrders,
} from '~/framework/server-api/schedule/order/routableOrders';
import { order$updateOrderCheckItemSymbol } from '~/framework/server-api/schedule/order/updateOrderCheckItem';
import { Maybe } from '~/framework/typeAliases';
import { IConnection } from '~/framework/server-api/connection';
import { ISearchOrdersApi, order$searchOrders } from '~/framework/server-api/schedule/order/searchOrders';

export class OrdersByDateApi extends GraphqlApiBase implements IOrdersByDateApi {
  [order$ordersByDateSymbol] = undefined;
  async ordersByDate(date: Date): Promise<IOrderData[]> {
    const result = await this.query<OrdersByDateQuery, OrdersByDateQueryVariables>({
      query: OrdersByDate,
      variables: { date },
    });
    return result.ordersByDate;
  }
}

export class OrdersByIdsApi extends GraphqlApiBase implements IOrdersByIdsApi {
  [order$ordersByIdsSymbol] = undefined;
  async ordersByIds(ids: string[]): Promise<IOrderData[]> {
    const result = await this.query<OrdersByIdsQuery, OrdersByIdsQueryVariables>({
      query: OrdersByIds,
      variables: { ids },
    });
    return result.ordersByIds;
  }
}

export class CreateOrdersApi extends GraphqlApiBase implements ICreateOrdersApi {
  [order$createOrdersSymbol] = undefined;

  async createOrders(data: ICreateOrderData[]): Promise<IResultOrderData[]> {
    const result = await this.mutate<CreateOrdersMutation, CreateOrdersMutationVariables>({
      mutation: CreateOrders,
      variables: { orders: data },
    });
    this.validateArrayConsistency(data, result.createOrders, `CreateOrders`);
    return result.createOrders;
  }
}

export class UpdateOrdersApi extends GraphqlApiBase implements IUpdateOrdersApi {
  [order$updateOrdersSymbol] = undefined;
  async updateOrders(data: IUpdateOrderData[]): Promise<IResultOrderData[]> {
    const result = await this.mutate<UpdateOrdersMutation, UpdateOrdersMutationVariables>({
      mutation: UpdateOrders,
      variables: { orders: data },
    });
    this.validateArrayConsistency(data, result.updateOrders, `UpdateOrders`);
    return result.updateOrders;
  }
}

export class CancelOrderApi extends GraphqlApiBase implements ICancelOrderApi {
  [order$cancelOrderSymbol] = undefined;
  async cancelOrder(id: string): Promise<IOrderData> {
    const result = await this.mutate<CancelOrderMutation, CancelOrderMutationVariables>({
      mutation: CancelOrder,
      variables: { id },
    });
    return result.cancelOrder;
  }
}

export class ActivateOrderApi extends GraphqlApiBase implements IActivateOrderApi {
  [order$activateOrderSymbol] = undefined;
  async activateOrder(id: string): Promise<IOrderData> {
    const result = await this.mutate<ActivateOrderMutation, ActivateOrderMutationVariables>({
      mutation: ActivateOrder,
      variables: { id },
    });
    return result.activateOrder;
  }
}

export class PostponeOrdersApi extends GraphqlApiBase implements IPostponeOrdersApi {
  [order$postponeOrdersSymbol] = undefined;
  async postponeOrders(data: IPostponeData[]): Promise<PostponeOrderResultTypes[]> {
    const result = await this.mutate<PostponeOrdersMutation, PostponeOrdersMutationVariables>({
      mutation: PostponeOrders,
      variables: { orders: data },
    });
    return result.postponeOrders;
  }
}

export class ValidateOrdersApi extends GraphqlApiBase implements IValidateOrdersApi {
  [order$validateOrdersSymbol] = undefined;
  async validateOrders(data: ICreateOrderData[]): Promise<RawScheduleJsonObject<RawRouteJsonObject>[]> {
    const result = await this.query<ValidateOrdersQuery, ValidateOrdersQueryVariables>({
      query: ValidateOrders,
      variables: { orders: data },
    });
    this.validateArrayConsistency(data, result.validateOrders, `ValidateOrders`);
    return result.validateOrders;
  }
}

// order があってはじめて存在し得るので order 以下に入れている、composition の様なもの
export class GenerationSiteTasksByIdsApi extends GraphqlApiBase implements IGenerationSiteTasksByIdsApi {
  // @ts-ignore
  [generationSiteTask$generationSiteTasksByIds]: void;
  async generationSiteTasksByIds(ids: string[]): Promise<IGenerationSiteTaskData[]> {
    const result = await this.query<GenerationSiteTasksByIdsQuery, GenerationSiteTasksByIdsQueryVariables>({
      query: GenerationSiteTasksByIds,
      variables: { ids },
    });
    // NOTE ここの typename は isGenerationSiteTaskNotFoundException で利用されているので変更する時は注意
    this.validateArrayConsistency(ids, result.generationSiteTasksByIds, 'GenerationSiteTasksByIds');
    return result.generationSiteTasksByIds;
  }
}

export class OrderDefaultByGenerationSiteIdApi extends GraphqlApiBase implements IOrderDefaultByGenerationSiteIdApi {
  // @ts-ignore
  [orderDefault$orderDefaultByGenerationSiteId]: void;
  async orderDefaultByGenerationSiteId(id: string): Promise<IOrderDefault> {
    const result = await this.query<OrderDefaultByGenerationSiteIdQuery, OrderDefaultByGenerationSiteIdQueryVariables>({
      query: OrderDefaultByGenerationSiteId,
      variables: { id },
    });
    return result.orderDefaultByGenerationSiteId;
  }
}

export class IrregularTaskByIdsApi extends GraphqlApiBase implements IIrregularTasksByIds {
  [irregularTask$irregularTasksByIdsSymbol] = undefined;
  async irregularTasksByIds(ids: string[]): Promise<IIrregularTaskData[]> {
    const result = await this.query<IrregularTasksByIdsQuery, IrregularTasksByIdsQueryVariables>({
      query: IrregularTasksByIds,
      variables: { ids },
    });
    // NOTE ここの typename は isGenerationSiteTaskNotFoundException で利用されているので変更する時は注意
    this.validateArrayConsistency(ids, result.irregularTasksByIds, 'IrregularTasksByIds');
    return result.irregularTasksByIds;
  }
}

// FIXME あとで消す
export class LatestOrdersApi extends GraphqlApiBase implements ILatestOrdersApi {
  // @ts-ignore
  [order$latestOrders]: void;
  async latestOrders(condition: ILatestOrdersCondition): Promise<IOrderData[]> {
    const result = await this.query<LatestOrdersQuery, LatestOrdersQueryVariables>({
      query: LatestOrders,
      variables: { condition },
    });
    return result.latestOrders;
  }
}

export class SearchOrdersApi extends GraphqlApiBase implements ISearchOrdersApi {
  // @ts-ignore
  [order$searchOrders]: void;
  async searchOrders(
    first: number,
    after: Maybe<string>,
    condition: SearchOrdersInput
  ): Promise<IConnection<IOrderData>> {
    const result = await this.query<SearchOrdersQuery, SearchOrdersQueryVariables>({
      query: SearchOrders,
      variables: { first, after, condition },
    });
    return result.searchOrders;
  }
}

export class RoutableOrdersApi extends GraphqlApiBase implements IRoutableOrdersApi {
  // @ts-ignore
  [order$routableOrders]: void;
  async routableOrders(condition: IRoutableOrdersCondition): Promise<IOrderData[]> {
    const result = await this.query<RoutableOrdersQuery, RoutableOrdersQueryVariables>({
      query: RoutableOrders,
      variables: { condition },
    });
    return result.routableOrders;
  }
}

export class UpdateOrderCheckItemApi extends GraphqlApiBase {
  [order$updateOrderCheckItemSymbol] = undefined;
  async updateOrderCheckItem(orderCheckItemId: string, checked: boolean): Promise<IOrderData> {
    const result = await this.mutate<UpdateOrderCheckItemMutation, UpdateOrderCheckItemMutationVariables>({
      mutation: UpdateOrderCheckItem,
      variables: { orderCheckItemId, checked },
    });
    return result.updateOrderCheckItem;
  }
}
