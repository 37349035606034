import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { UsersCondition } from '~/framework/server-api/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { IGetByIds, IUsers } from '~/framework/server-api/masters/user';
import { UsersByIdsQuery, UsersByIdsQueryVariables, UsersQuery, UsersQueryVariables } from '~/graphql/graphQLServerApi';
import { Users, UsersByIds } from '@/../graphql/server-api/queries/user.gql';

export class UsersApi extends GraphqlApiBase implements IUsers, IGetByIds {
  async search(first: number, after: Maybe<string>, condition: UsersCondition) {
    const result = await this.query<UsersQuery, UsersQueryVariables>({
      query: Users,
      variables: { first, after, condition },
    });
    return result.users;
  }

  async getByIds(ids: string[]) {
    const result = await this.query<UsersByIdsQuery, UsersByIdsQueryVariables>({
      query: UsersByIds,
      variables: { ids },
    });
    return result.usersByIds;
  }
}
