export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Iso8601DateTime: Date;
  Iso8601Date: Date;
  Second: number;
  OrderAcceptanceCheckJsonObject: any;
  Upload: File;
  OrderValidationJsonObject: any;
  ScheduleResponseJsonObject: any;
  ScheduleRequestJsonObject: any;
};

export type AcceptReservationInput = {
  id: Scalars['ID'];
  order: CreateOrderInput;
};

export type AcceptReservationMutationResult = {
  __typename?: 'AcceptReservationMutationResult';
  id: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type AllDriverAttendancesByDateRangeInput = {
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export type Announcement = {
  __typename?: 'Announcement';
  id: Scalars['ID'];
  subject: Scalars['String'];
  body: Scalars['String'];
  publishedSince: Scalars['Iso8601DateTime'];
  publishedUntil: Maybe<Scalars['Iso8601DateTime']>;
  isRead: Scalars['Boolean'];
};

export type Attendance = {
  __typename?: 'Attendance';
  id: Scalars['ID'];
  date: Scalars['Iso8601Date'];
};

export type AttendancesByDateRangeInput = {
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export type BaseSite = {
  __typename?: 'BaseSite';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  hasCars: Scalars['Boolean'];
  hasContainers: Scalars['Boolean'];
  createdAt: Scalars['Iso8601DateTime'];
  createdBy: User;
};

export type BaseSiteMutationError = {
  __typename?: 'BaseSiteMutationError';
  errors: Array<BaseSiteMutationErrorTypes>;
};

export type BaseSiteMutationErrorTypes =
  | DuplicatedNameError
  | HasCarsMustBeTrueError
  | HasCarsOrHasContainersMustBeTrueError;

export type BaseSiteMutationResult = BaseSite | BaseSiteMutationError;

export type BaseTaskType = {
  __typename?: 'BaseTaskType';
  id: Scalars['ID'];
  name: BaseTaskTypeName;
  orderKind: OrderKind;
  siteType: SiteType;
};

export enum BaseTaskTypeName {
  DisposeAtDisposalSite = 'DisposeAtDisposalSite',
  LoadAtBaseSite = 'LoadAtBaseSite',
  UnloadAtBaseSite = 'UnloadAtBaseSite',
  ReplaceAtGenerationSite = 'ReplaceAtGenerationSite',
  AllocateAtGenerationSite = 'AllocateAtGenerationSite',
  FetchAtGenerationSite = 'FetchAtGenerationSite',
  LoadAtGenerationSite = 'LoadAtGenerationSite',
}

export type BooleanConstValue = IBooleanValue & {
  __typename?: 'BooleanConstValue';
  value: Scalars['Boolean'];
};

export type BooleanOfficeDefaultValue = IBooleanValue & {
  __typename?: 'BooleanOfficeDefaultValue';
  value: Scalars['Boolean'];
};

export type CancelReservationInput = {
  id: Scalars['ID'];
};

export type CancelReservationMutationResult = {
  __typename?: 'CancelReservationMutationResult';
  id: Scalars['ID'];
};

export type CandidateDate = {
  __typename?: 'CandidateDate';
  date: Scalars['Iso8601Date'];
  vacancy: Vacancy;
  collectablePeriodTemplateName: Scalars['String'];
  collectablePeriodStart: Scalars['Second'];
  collectablePeriodEnd: Scalars['Second'];
};

export type Car = {
  __typename?: 'Car';
  id: Scalars['ID'];
  name: Scalars['String'];
  simpleCarType: SimpleCarType;
  baseSite: BaseSite;
  status: SoftDeleteStatus;
  enabledOn: Scalars['Iso8601Date'];
  code: Maybe<Scalars['String']>;
};

export type CarAttendance = {
  __typename?: 'CarAttendance';
  id: Scalars['ID'];
  attendance: Attendance;
  carId: Scalars['ID'];
  carAttendanceType: CarAttendanceType;
  periodStart: Maybe<Scalars['Second']>;
  periodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  unavailableReason: Maybe<Scalars['String']>;
};

export type CarAttendancesByDateRangeInput = {
  carIds: Maybe<Array<Scalars['ID']>>;
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export enum CarAttendanceType {
  AvailableAllDay = 'AvailableAllDay',
  UnavailableAllDay = 'UnavailableAllDay',
  AvailableInSpecifiedTime = 'AvailableInSpecifiedTime',
  UnavailableInSpecifiedTime = 'UnavailableInSpecifiedTime',
}

export type CarMutationError = {
  __typename?: 'CarMutationError';
  errors: Array<CarMutationErrorTypes>;
};

export type CarMutationErrorTypes = DuplicatedNameError | DuplicatedCodeError;

export type CarMutationResultTypes = Car | CarMutationError;

export type CarType = {
  __typename?: 'CarType';
  id: Scalars['ID'];
  name: Scalars['String'];
  orderGroup: OrderGroup;
  drivingSpeedCoefficient: Scalars['Float'];
  durationOfBoarding: Scalars['Second'];
  durationOfAlighting: Scalars['Second'];
  loadableContainerTypes: Array<CarTypeContainerType>;
};

export type CarTypeContainerType = {
  __typename?: 'CarTypeContainerType';
  id: Scalars['ID'];
  containerType: ContainerType;
  emptyCount: Scalars['Float'];
  emptyCountOccupation: Scalars['Float'];
  lowerTierFullCount: Scalars['Float'];
  upperTierFullCount: Scalars['Float'];
  fullCountOccupation: Scalars['Float'];
  isUpperTierAvailable: Scalars['Boolean'];
};

export type CarTypeMutationError = {
  __typename?: 'CarTypeMutationError';
  errors: Array<CarTypeMutationErrorTypes>;
};

export type CarTypeMutationErrorTypes = DuplicatedNameError;

export type CarTypeMutationResultTypes = CarType | CarTypeMutationError;

export type CheckItem = {
  __typename?: 'CheckItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  default: Scalars['Boolean'];
  status: SoftDeleteStatus;
};

export type CheckItemInput = {
  name: Scalars['String'];
  default: Scalars['Boolean'];
};

export type CheckItemMutationError = {
  __typename?: 'CheckItemMutationError';
  errors: Array<CheckItemMutationErrorTypes>;
};

export type CheckItemMutationErrorTypes = DuplicatedNameError;

export type CheckItemMutationResultTypes = CheckItem | CheckItemMutationError;

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  code: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  note: Maybe<Scalars['String']>;
  createdAt: Scalars['Iso8601DateTime'];
  createdBy: User;
  updatedAt: Scalars['Iso8601DateTime'];
  updatedBy: User;
  generationSiteNum: Scalars['Int'];
  status: SoftDeleteStatus;
  signInUrl: Maybe<Scalars['String']>;
  reservationSiteEnabled: Scalars['Boolean'];
  packingStyleReservationSettings: Array<ClientPackingStyleReservationSetting>;
};

export type ClientContainerTypeReservationSetting = {
  __typename?: 'ClientContainerTypeReservationSetting';
  containerType: ContainerType;
  visible: Maybe<Scalars['Boolean']>;
};

export type ClientContainerTypeReservationSettingInput = {
  containerTypeId: Scalars['ID'];
  visible: Maybe<Scalars['Boolean']>;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  node: Client;
  cursor: Scalars['String'];
};

export type ClientPackingStyleReservationSetting = {
  __typename?: 'ClientPackingStyleReservationSetting';
  packingStyle: PackingStyle;
  visible: Scalars['Boolean'];
  containerTypeReservationSettings: Array<ClientContainerTypeReservationSetting>;
};

export type ClientPackingStyleReservationSettingInput = {
  packingStyleId: Scalars['ID'];
  visible: Scalars['Boolean'];
  containerTypeReservationSettings: Array<ClientContainerTypeReservationSettingInput>;
};

export type ClientResult = {
  __typename?: 'ClientResult';
  client: Client;
};

export type ClientsByKeywordsCondition = {
  keywords: Maybe<Array<Scalars['String']>>;
  since: Maybe<Scalars['Iso8601Date']>;
  orderBy: ClientsByKeywordsOrder;
};

export type ClientsByKeywordsConnection = {
  __typename?: 'ClientsByKeywordsConnection';
  edges: Array<ClientEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export enum ClientsByKeywordsOrder {
  ClientNameRubyAsc = 'ClientNameRubyAsc',
  ClientNameRubyDesc = 'ClientNameRubyDesc',
  CreatedAtAsc = 'CreatedAtAsc',
  CreatedAtDesc = 'CreatedAtDesc',
}

export type CollectablePeriodTemplate = {
  __typename?: 'CollectablePeriodTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export type ContainerType = {
  __typename?: 'ContainerType';
  id: Scalars['ID'];
  name: Scalars['String'];
  packingStyle: PackingStyle;
  unitName: Scalars['String'];
  transformingCarType: Maybe<SimpleCarType>;
  status: SoftDeleteStatus;
  reservationSiteEnabled: Scalars['Boolean'];
};

export type ContainerTypeTaskType = {
  __typename?: 'ContainerTypeTaskType';
  id: Scalars['ID'];
  containerType: ContainerType;
  taskType: TaskType;
  values: ContainerTypeTaskTypeValue;
};

export enum ContainerTypeTaskTypeStatus {
  InUse = 'InUse',
  NotInUse = 'NotInUse',
}

export type ContainerTypeTaskTypeStatusConstValue = IContainerTypeTaskTypeStatusValue & {
  __typename?: 'ContainerTypeTaskTypeStatusConstValue';
  value: ContainerTypeTaskTypeStatus;
};

export type ContainerTypeTaskTypeStatusOfficeDefaultValue = IContainerTypeTaskTypeStatusValue & {
  __typename?: 'ContainerTypeTaskTypeStatusOfficeDefaultValue';
  value: ContainerTypeTaskTypeStatus;
};

export type ContainerTypeTaskTypeValue = {
  __typename?: 'ContainerTypeTaskTypeValue';
  duration: IIntegerValue;
  status: IContainerTypeTaskTypeStatusValue;
  isProportionalToCount: IBooleanValue;
};

export type CreateAttendanceInput = {
  date: Scalars['Iso8601Date'];
};

export type CreateBaseSiteInput = {
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  hasCars: Scalars['Boolean'];
  hasContainers: Scalars['Boolean'];
};

export type CreateCarInput = {
  name: Scalars['String'];
  carTypeId: Scalars['ID'];
  baseSiteId: Scalars['ID'];
  enabledOn: Scalars['Iso8601Date'];
  code: Maybe<Scalars['String']>;
};

export type CreateCarTypeContainerTypeInput = {
  containerTypeId: Scalars['ID'];
  emptyCount: Scalars['Float'];
  emptyCountOccupation: Scalars['Float'];
  lowerTierFullCount: Scalars['Float'];
  upperTierFullCount: Scalars['Float'];
  fullCountOccupation: Scalars['Float'];
  isUpperTierAvailable: Scalars['Boolean'];
};

export type CreateCarTypeInput = {
  name: Scalars['String'];
  orderGroupId: Scalars['ID'];
  drivingSpeedCoefficient: Scalars['Float'];
  durationOfBoarding: Scalars['Second'];
  durationOfAlighting: Scalars['Second'];
  loadableContainerTypes: Array<CreateCarTypeContainerTypeInput>;
};

export type CreateClientInput = {
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  note: Maybe<Scalars['String']>;
  status: SoftDeleteStatus;
};

export type CreateCollectablePeriodTemplateInput = {
  name: Scalars['String'];
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export type CreateContainerTypeInput = {
  name: Scalars['String'];
  packingStyleId: Scalars['ID'];
  unitName: Scalars['String'];
  transformingCarTypeId: Maybe<Scalars['ID']>;
  status: SoftDeleteStatus;
  reservationSiteEnabled: Maybe<Scalars['Boolean']>;
};

export type CreateDefaultDriverAttendanceInput = {
  driverId: Scalars['ID'];
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export type CreateDefaultMultipleDriversAttendanceInput = {
  driverIds: Array<Scalars['ID']>;
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export type CreateDefaultMultipleDriversAttendancesPayload = {
  __typename?: 'CreateDefaultMultipleDriversAttendancesPayload';
  skippedDriverIds: Array<Scalars['ID']>;
};

export type CreateDisposalSiteInput = {
  clientId: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  disposablePeriodStart: Scalars['Second'];
  disposablePeriodEnd: Scalars['Second'];
  durationAtEntrance: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  acceptanceLimit: Scalars['Int'];
  temporalAcceptanceInterval: Maybe<Scalars['Second']>;
  temporalAcceptanceLimit: Maybe<Scalars['Int']>;
  note: Maybe<Scalars['String']>;
  isAddressComplete: Scalars['Boolean'];
};

export type CreateDisposalSiteResult = {
  __typename?: 'CreateDisposalSiteResult';
  id: Scalars['ID'];
  createdAt: Scalars['Iso8601DateTime'];
  createdBy: User;
};

export type CreateDriverAttendanceInput = {
  attendanceId: Scalars['ID'];
  driverId: Scalars['ID'];
  primaryCarId: Scalars['ID'];
  forceRidePrimaryCar: Maybe<Scalars['Boolean']>;
  templateName: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
};

export type CreateDriverAttendanceTemplateInput = {
  name: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
  defaultForceRidePrimaryCar: Scalars['Boolean'];
};

export type CreateDriverInput = {
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  employmentStatus: EmploymentStatus;
  defaultPrimaryCarId: Maybe<Scalars['ID']>;
  licensedCarTypeIds: Array<Scalars['ID']>;
  defaultAttendanceTemplateId: Maybe<Scalars['ID']>;
  status: Maybe<SoftDeleteStatus>;
  code: Maybe<Scalars['String']>;
};

export type CreateErpOrderInput = {
  orderId: Scalars['ID'];
  transportationClientId: Scalars['ID'];
  withDisposalItemFromScheduling: Scalars['Boolean'];
  note: Maybe<Scalars['String']>;
  erpOrderItems: Array<CreateErpOrderItemInput>;
  isRecurring: Maybe<Scalars['Boolean']>;
};

export type CreateErpOrderItemInput = {
  generationSiteTaskId: Maybe<Scalars['ID']>;
  irregularTaskId: Maybe<Scalars['ID']>;
  apparentQuantity: Maybe<Scalars['Float']>;
  apparentQuantityUnit: Maybe<Scalars['String']>;
};

export type CreateGenerationSiteInput = {
  clientId: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  bannedDriverIds: Array<Scalars['ID']>;
  defaultAssignedDriverId: Maybe<Scalars['ID']>;
  defaultAssignedDisposalSiteId: Maybe<Scalars['ID']>;
  defaultAssignableCarIds: Array<Scalars['ID']>;
  defaultAssignableCarTypeIds: Array<Scalars['ID']>;
  defaultAvoidHighways: Scalars['Boolean'];
  defaultDurationAtEntrance: Scalars['Second'];
  defaultCollectablePeriodTemplateId: Maybe<Scalars['ID']>;
  defaultCollectablePeriodStart: Maybe<Scalars['Second']>;
  defaultCollectablePeriodEnd: Maybe<Scalars['Second']>;
  defaultRouteCollectionAllowed: Maybe<Scalars['Boolean']>;
  defaultPreloadStatus: Maybe<PreloadStatus>;
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  noteForOffice: Maybe<Scalars['String']>;
  attachmentsToAdd: Maybe<Array<Scalars['Upload']>>;
  isAddressComplete: Scalars['Boolean'];
  defaultIsFixedArrivalTimeReportNeeded: Maybe<Scalars['Boolean']>;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: Scalars['Second'];
  status: SoftDeleteStatus;
};

export type CreateGenerationSiteResult = {
  __typename?: 'CreateGenerationSiteResult';
  id: Scalars['ID'];
  createdAt: Scalars['Iso8601DateTime'];
};

export type CreateGenerationSiteTaskInput = {
  taskTypeId: Scalars['ID'];
  wasteTypeId: Maybe<Scalars['ID']>;
  containerTypeId: Scalars['ID'];
  containerNum: Scalars['Float'];
};

export type CreateIrregularTaskInput = {
  name: Scalars['String'];
  durationAtGenerationSite: Scalars['Second'];
  durationAtDisposalSite: Scalars['Second'];
  skipDisposalSite: Scalars['Boolean'];
};

export type CreateOrderAcceptanceCheckInput = {
  order: UpdateOrderInput;
  reservationId: Maybe<Scalars['ID']>;
};

export type CreateOrderAcceptanceCheckResult = {
  __typename?: 'CreateOrderAcceptanceCheckResult';
  id: Scalars['ID'];
  acceptanceData: Maybe<Scalars['OrderAcceptanceCheckJsonObject']>;
  status: OrderAcceptanceCheckStatus;
};

export type CreateOrderAssignableDriverInput = {
  driverType: DriverType;
  driverId: Scalars['ID'];
};

export type CreateOrderAssignableDriversAndNumInput = {
  minAssignedDriverNum: Scalars['Int'];
  maxAssignedDriverNum: Scalars['Int'];
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: Maybe<Array<CreateOrderAssignableDriverInput>>;
};

export type CreateOrderDisposalSiteInput = {
  disposalSiteId: Scalars['ID'];
  priority: Maybe<OrderDisposalSitePriority>;
  durationAtEntrance: Scalars['Second'];
  disposablePeriodStart: Scalars['Second'];
  disposablePeriodEnd: Scalars['Second'];
};

export type CreateOrderDisposalSitesAndTypeInput = {
  orderDisposalSites: Array<CreateOrderDisposalSiteInput>;
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
};

export type CreateOrderInput = {
  plan: OrderPlanInput;
  orderGroupId: Scalars['ID'];
  generationSiteId: Scalars['ID'];
  generationSiteTasks: Array<CreateGenerationSiteTaskInput>;
  irregularTasks: Array<CreateIrregularTaskInput>;
  durationAtGenerationSite: Scalars['Second'];
  routeCollectionAllowed: Scalars['Boolean'];
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignableCarIds: Array<Scalars['ID']>;
  assignableCarTypeIds: Array<Scalars['ID']>;
  carNum: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  noteForAssignedDriver: Maybe<Scalars['String']>;
  attachmentsToAdd: Maybe<Array<Scalars['Upload']>>;
  avoidHighways: Scalars['Boolean'];
  status: OrderStatus;
  recurringSettings: Maybe<CreateOrderRecurringSettingsInput>;
  preloadStatus: PreloadStatus;
  assignAssignableDriversOnUnloadDate: Maybe<Scalars['Boolean']>;
  fixedArrivalTime: Maybe<Scalars['Second']>;
  isFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: Scalars['Second'];
  checkItemIds: Array<Scalars['ID']>;
  routingGroup: Maybe<OrderRoutingGroupInput>;
  fixedDisplayOnReservation: Scalars['Boolean'];
  fixedDisplayOnReservationName: Maybe<Scalars['String']>;
  schedulingPriority: OrderSchedulingPriority;
};

export type CreateOrderRecurringSettingsInput = {
  type: OrderRecurringSettingsType;
  step: Scalars['Int'];
  daysOfWeek: Maybe<Array<DayOfWeek>>;
  daysOfMonth: Maybe<Array<DayOfMonthInput>>;
  includeNationalHolidays: Scalars['Boolean'];
  endAt: Maybe<Scalars['Iso8601Date']>;
};

export type CreateRoutingRegulationInput = {
  name: Scalars['String'];
  routingRegulationType: RoutingRegulationType;
  generationSiteIds: Maybe<Array<Scalars['ID']>>;
  optionalGenerationSiteIds: Maybe<Array<Scalars['ID']>>;
  preserveGenerationSiteSequence: Maybe<Scalars['Boolean']>;
};

export type CreateScheduleInput = {
  attendanceId: Scalars['ID'];
  orderGroupId: Scalars['ID'];
  scheduleResponse: Maybe<Scalars['ScheduleResponseJsonObject']>;
};

export type CreateTaskTypeInput = {
  name: Scalars['String'];
  baseTaskTypeId: Scalars['ID'];
  bannedDriverIds: Maybe<Array<Scalars['ID']>>;
};

export type CreateWasteTypeInput = {
  category: WasteCategory;
  code: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: WasteTypeStatus;
  isProminent: Scalars['Boolean'];
};

export type DayOfMonth = {
  __typename?: 'DayOfMonth';
  weekdayOrdinal: WeekdayOrdinal;
  dayOfWeek: DayOfWeek;
};

export type DayOfMonthInput = {
  weekdayOrdinal: WeekdayOrdinal;
  dayOfWeek: DayOfWeek;
};

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export type DisposalSite = {
  __typename?: 'DisposalSite';
  id: Scalars['ID'];
  code: Maybe<Scalars['String']>;
  client: Maybe<Client>;
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  disposablePeriodStart: Scalars['Second'];
  disposablePeriodEnd: Scalars['Second'];
  durationAtEntrance: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  acceptanceLimit: Scalars['Int'];
  temporalAcceptanceInterval: Maybe<Scalars['Second']>;
  temporalAcceptanceLimit: Maybe<Scalars['Int']>;
  note: Maybe<Scalars['String']>;
  isAddressComplete: Scalars['Boolean'];
  createdAt: Scalars['Iso8601DateTime'];
  createdBy: User;
};

export type DisposalSiteAttendance = {
  __typename?: 'DisposalSiteAttendance';
  id: Scalars['ID'];
  disposalSite: DisposalSite;
  attendance: Attendance;
  periodStart: Maybe<Scalars['Second']>;
  periodEnd: Maybe<Scalars['Second']>;
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: Scalars['Int'];
  note: Maybe<Scalars['String']>;
};

export type DisposalSiteAttendancesByDateRangeInput = {
  disposalSiteIds: Maybe<Array<Scalars['ID']>>;
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export enum DisposalSiteAttendanceStatus {
  AvailableInSpecifiedTime = 'AvailableInSpecifiedTime',
  UnavailableAllDay = 'UnavailableAllDay',
}

export type Driver = {
  __typename?: 'Driver';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  employmentStatus: EmploymentStatus;
  defaultPrimaryCar: Maybe<Car>;
  licensedCarTypes: Array<SimpleCarType>;
  defaultAttendanceTemplate: Maybe<DriverAttendanceTemplate>;
  signInUrl: Scalars['String'];
  status: SoftDeleteStatus;
  code: Maybe<Scalars['String']>;
};

export enum DriverAssignmentType {
  NotDistinguished = 'NotDistinguished',
  Distinguished = 'Distinguished',
}

export type DriverAttendance = {
  __typename?: 'DriverAttendance';
  id: Scalars['ID'];
  attendance: Attendance;
  driver: Driver;
  primaryCar: Car;
  forceRidePrimaryCar: Scalars['Boolean'];
  templateName: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
};

export type DriverAttendancesByDateRangeInput = {
  driverId: Scalars['ID'];
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export type DriverAttendanceTemplate = {
  __typename?: 'DriverAttendanceTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
  defaultForceRidePrimaryCar: Scalars['Boolean'];
};

export type DriverMutationError = {
  __typename?: 'DriverMutationError';
  errors: Array<DriverMutationErrorTypes>;
};

export type DriverMutationErrorTypes = DuplicatedCodeError;

export type DriverMutationResultTypes = Driver | DriverMutationError;

export enum DriverType {
  Driver = 'Driver',
  Operator = 'Operator',
  Helper = 'Helper',
}

export type DuplicatedCodeError = {
  __typename?: 'DuplicatedCodeError';
  name: Scalars['String'];
};

export type DuplicatedNameError = {
  __typename?: 'DuplicatedNameError';
  name: Scalars['String'];
};

export enum EmploymentStatus {
  Permanent = 'Permanent',
  Contract = 'Contract',
  FieldWorker = 'FieldWorker',
}

export type ErpClient = {
  __typename?: 'ErpClient';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ErpOrder = {
  __typename?: 'ErpOrder';
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  transportationClient: ErpClient;
  withDisposalItemFromScheduling: Scalars['Boolean'];
  note: Maybe<Scalars['String']>;
  erpOrderItems: Array<ErpOrderItem>;
};

export type ErpOrderItem = {
  __typename?: 'ErpOrderItem';
  id: Scalars['ID'];
  erpOrderId: Scalars['ID'];
  generationSiteTaskId: Maybe<Scalars['ID']>;
  irregularTaskId: Maybe<Scalars['ID']>;
  apparentQuantity: Maybe<Scalars['Float']>;
  apparentQuantityUnit: Maybe<Scalars['String']>;
};

export type GenerationSite = {
  __typename?: 'GenerationSite';
  id: Scalars['ID'];
  code: Maybe<Scalars['String']>;
  client: Client;
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  bannedDrivers: Array<Driver>;
  defaultAssignedDriver: Maybe<Driver>;
  defaultAssignedDisposalSite: Maybe<DisposalSite>;
  defaultAssignableCars: Array<Car>;
  defaultAssignableCarTypes: Array<SimpleCarType>;
  defaultAvoidHighways: Scalars['Boolean'];
  defaultDurationAtEntrance: Scalars['Second'];
  defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplate>;
  defaultCollectablePeriodStart: Maybe<Scalars['Second']>;
  defaultCollectablePeriodEnd: Maybe<Scalars['Second']>;
  defaultRouteCollectionAllowed: Maybe<Scalars['Boolean']>;
  defaultPreloadStatus: Maybe<PreloadStatus>;
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  createdBy: User;
  note: Maybe<Scalars['String']>;
  noteForOffice: Maybe<Scalars['String']>;
  attachments: Maybe<Array<UploadedFile>>;
  isAddressComplete: Scalars['Boolean'];
  defaultIsFixedArrivalTimeReportNeeded: Maybe<Scalars['Boolean']>;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: Scalars['Second'];
  createdAt: Scalars['Iso8601DateTime'];
  status: SoftDeleteStatus;
};

export type GenerationSiteEdge = {
  __typename?: 'GenerationSiteEdge';
  node: GenerationSite;
  cursor: Scalars['String'];
};

export type GenerationSitesByKeywordsCondition = {
  clientId: Maybe<Scalars['ID']>;
  keywords: Maybe<Array<Scalars['String']>>;
  since: Maybe<Scalars['Iso8601Date']>;
  orderBy: GenerationSitesByKeywordsOrder;
};

export type GenerationSitesByKeywordsConnection = {
  __typename?: 'GenerationSitesByKeywordsConnection';
  edges: Array<GenerationSiteEdge>;
  nodes: Array<GenerationSite>;
  pageInfo: PageInfo;
};

export enum GenerationSitesByKeywordsOrder {
  ClientNameRubyAsc = 'ClientNameRubyAsc',
  ClientNameRubyDesc = 'ClientNameRubyDesc',
  CreatedAtAsc = 'CreatedAtAsc',
  CreatedAtDesc = 'CreatedAtDesc',
}

export type GenerationSiteTask = {
  __typename?: 'GenerationSiteTask';
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  taskType: TaskType;
  wasteType: Maybe<WasteType>;
  containerType: ContainerType;
  containerNum: Scalars['Float'];
};

export type HasCarsMustBeTrueError = {
  __typename?: 'HasCarsMustBeTrueError';
  name: Scalars['String'];
};

export type HasCarsOrHasContainersMustBeTrueError = {
  __typename?: 'HasCarsOrHasContainersMustBeTrueError';
  name: Scalars['String'];
};

export type HolidayRule = {
  __typename?: 'HolidayRule';
  id: Scalars['ID'];
  monday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  friday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  nationalHoliday: Scalars['Boolean'];
};

export type IBooleanValue = {
  value: Scalars['Boolean'];
};

export type IContainerTypeTaskTypeStatusValue = {
  value: ContainerTypeTaskTypeStatus;
};

export type IIntegerValue = {
  value: Scalars['Int'];
};

export type IntegerConstValue = IIntegerValue & {
  __typename?: 'IntegerConstValue';
  value: Scalars['Int'];
};

export type IntegerOfficeDefaultValue = IIntegerValue & {
  __typename?: 'IntegerOfficeDefaultValue';
  value: Scalars['Int'];
};

export type IPackingPlacementsAtSite = {
  packingPlacements: Array<PackingPlacement>;
  lastAllocatedAt: Maybe<Scalars['Iso8601DateTime']>;
  managementStartedOn: Maybe<Scalars['Iso8601Date']>;
};

export type IRoutingRegulation = {
  id: Scalars['ID'];
  name: Scalars['String'];
  createdBy: User;
  updatedBy: User;
  createdAt: Scalars['Iso8601DateTime'];
  updatedAt: Scalars['Iso8601DateTime'];
};

export type IrregularTask = {
  __typename?: 'IrregularTask';
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  name: Scalars['String'];
  durationAtGenerationSite: Scalars['Second'];
  durationAtDisposalSite: Scalars['Second'];
  skipDisposalSite: Scalars['Boolean'];
};

export type JwnetWasteMaster = {
  __typename?: 'JwnetWasteMaster';
  id: Scalars['ID'];
  firstCategoryName: Scalars['String'];
  secondCategoryName: Maybe<Scalars['String']>;
  thirdCategoryName: Maybe<Scalars['String']>;
  code: Scalars['String'];
  isUsable: Scalars['Boolean'];
};

export type LatestOrdersInput = {
  clientId: Maybe<Scalars['ID']>;
  generationSiteIds: Array<Scalars['ID']>;
  startAt: Maybe<Scalars['Iso8601Date']>;
  endAt: Maybe<Scalars['Iso8601Date']>;
  createdStartAt: Maybe<Scalars['Iso8601Date']>;
  createdEndAt: Maybe<Scalars['Iso8601Date']>;
  note: Maybe<Scalars['String']>;
  wasteTypeId: Maybe<Scalars['ID']>;
  includeCancelled: Maybe<Scalars['Boolean']>;
  includeFollowingRecurringOrder: Maybe<Scalars['Boolean']>;
  onlyFixedDisplayOnReservation: Maybe<Scalars['Boolean']>;
};

export type LatestSchedulesInput = {
  attendanceId: Scalars['ID'];
  orderGroupId: Scalars['ID'];
  statuses: Array<ScheduleStatus>;
};

export enum MarginType {
  AllowBeforeAndAfter = 'AllowBeforeAndAfter',
  AllowBefore = 'AllowBefore',
  AllowAfter = 'AllowAfter',
}

export type Mutation = {
  __typename?: 'Mutation';
  createAttendances: Array<Scalars['ID']>;
  createBaseSite: BaseSiteMutationResult;
  updateBaseSite: BaseSiteMutationResult;
  updateCarAttendance: CarAttendance;
  createCarType: CarTypeMutationResultTypes;
  updateCarType: CarTypeMutationResultTypes;
  createCar: CarMutationResultTypes;
  updateCar: CarMutationResultTypes;
  deleteCar: Car;
  createCheckItem: CheckItemMutationResultTypes;
  updateCheckItem: CheckItemMutationResultTypes;
  deleteCheckItem: CheckItem;
  updateCheckItemSequence: Array<Scalars['ID']>;
  createClients: Array<ClientResult>;
  updateClients: Array<ClientResult>;
  createCollectablePeriodTemplates: Array<Scalars['ID']>;
  updateCollectablePeriodTemplates: Array<Scalars['ID']>;
  updateContainerTypeTaskTypes: Array<Scalars['ID']>;
  updatePackingStyleTaskTypeDefaults: Array<PackingStyleTaskTypeDefault>;
  createContainerType: ContainerType;
  updateContainerType: ContainerType;
  updateDisposalSiteAttendance: DisposalSiteAttendance;
  createDisposalSites: Array<CreateDisposalSiteResult>;
  updateDisposalSites: Array<Scalars['ID']>;
  createDriverAttendanceTemplates: Array<Scalars['ID']>;
  updateDriverAttendanceTemplates: Array<Scalars['ID']>;
  createDriverAttendances: Array<Scalars['ID']>;
  createDefaultDriverAttendances: Array<DriverAttendance>;
  createDefaultMultipleDriversAttendances: CreateDefaultMultipleDriversAttendancesPayload;
  updateDriverAttendances: Array<Scalars['ID']>;
  deleteDriverAttendances: Array<Scalars['ID']>;
  updateDriverSequence: Array<Scalars['ID']>;
  createDriver: DriverMutationResultTypes;
  /** @deprecated use createDriver instead */
  createDrivers: Array<Scalars['ID']>;
  updateDriver: DriverMutationResultTypes;
  /** @deprecated use updateDriver instead */
  updateDrivers: Array<Scalars['ID']>;
  deleteDriver: Driver;
  createErpOrder: ErpOrder;
  updateErpOrder: ErpOrder;
  deleteErpOrderByOrderId: Scalars['ID'];
  createGenerationSites: Array<CreateGenerationSiteResult>;
  updateGenerationSites: Array<Scalars['ID']>;
  updateHolidayRules: Array<Scalars['ID']>;
  updateOfficeSettings: Array<Scalars['ID']>;
  createOrderAcceptanceCheck: CreateOrderAcceptanceCheckResult;
  updateOrders: Array<OrderMutationResult>;
  createOrders: Array<OrderMutationResult>;
  cancelOrder: Order;
  activateOrder: Order;
  postponeOrders: Array<PostponeOrderResult>;
  updateOrderCheckItem: Order;
  updatePackingPlacements: Array<PackingPlacement>;
  acceptReservation: AcceptReservationMutationResult;
  cancelReservation: CancelReservationMutationResult;
  pushBackReservationFromCancelledToWaitingReview: PushBackReservationFromCancelledToWaitingReviewResult;
  updateReservationDeadline: ReservationDeadline;
  createRoutingRegulation: RoutingRegulationMutationResultTypes;
  updateRoutingRegulation: RoutingRegulationMutationResultTypes;
  deleteRoutingRegulation: Scalars['ID'];
  updatePublishingStatusOfSchedule: Scalars['ID'];
  createSchedules: Array<Scalars['ID']>;
  createTaskType: TaskTypeMutationResultTypes;
  updateTaskType: TaskTypeMutationResultTypes;
  createWasteType: WasteTypeCreateResultTypes;
  updateWasteType: WasteType;
};

export type MutationCreateAttendancesArgs = {
  attendances: Array<CreateAttendanceInput>;
};

export type MutationCreateBaseSiteArgs = {
  site: CreateBaseSiteInput;
};

export type MutationUpdateBaseSiteArgs = {
  site: UpdateBaseSiteInput;
};

export type MutationUpdateCarAttendanceArgs = {
  carAttendance: UpdateCarAttendanceInput;
};

export type MutationCreateCarTypeArgs = {
  carType: CreateCarTypeInput;
};

export type MutationUpdateCarTypeArgs = {
  carType: UpdateCarTypeInput;
};

export type MutationCreateCarArgs = {
  car: CreateCarInput;
};

export type MutationUpdateCarArgs = {
  car: UpdateCarInput;
};

export type MutationDeleteCarArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCheckItemArgs = {
  checkItem: CheckItemInput;
};

export type MutationUpdateCheckItemArgs = {
  id: Scalars['ID'];
  checkItem: CheckItemInput;
};

export type MutationDeleteCheckItemArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCheckItemSequenceArgs = {
  checkItemSequence: Array<Scalars['ID']>;
};

export type MutationCreateClientsArgs = {
  clients: Array<CreateClientInput>;
};

export type MutationUpdateClientsArgs = {
  clients: Array<UpdateClientInput>;
};

export type MutationCreateCollectablePeriodTemplatesArgs = {
  collectablePeriodTemplates: Array<CreateCollectablePeriodTemplateInput>;
};

export type MutationUpdateCollectablePeriodTemplatesArgs = {
  collectablePeriodTemplates: Array<UpdateCollectablePeriodTemplateInput>;
};

export type MutationUpdateContainerTypeTaskTypesArgs = {
  containerTypeTaskTypes: Array<UpdateContainerTypeTaskTypeInput>;
};

export type MutationUpdatePackingStyleTaskTypeDefaultsArgs = {
  packingStyleTaskTypeDefaults: Array<UpdatePackingStyleTaskTypeDefaultInput>;
};

export type MutationCreateContainerTypeArgs = {
  containerType: CreateContainerTypeInput;
};

export type MutationUpdateContainerTypeArgs = {
  containerType: UpdateContainerTypeInput;
};

export type MutationUpdateDisposalSiteAttendanceArgs = {
  disposalSiteAttendance: UpdateDisposalSiteAttendanceInput;
};

export type MutationCreateDisposalSitesArgs = {
  disposalSites: Array<CreateDisposalSiteInput>;
};

export type MutationUpdateDisposalSitesArgs = {
  disposalSites: Array<UpdateDisposalSiteInput>;
};

export type MutationCreateDriverAttendanceTemplatesArgs = {
  driverAttendanceTemplates: Array<CreateDriverAttendanceTemplateInput>;
};

export type MutationUpdateDriverAttendanceTemplatesArgs = {
  driverAttendanceTemplates: Array<UpdateDriverAttendanceTemplateInput>;
};

export type MutationCreateDriverAttendancesArgs = {
  driverAttendances: Array<CreateDriverAttendanceInput>;
};

export type MutationCreateDefaultDriverAttendancesArgs = {
  condition: CreateDefaultDriverAttendanceInput;
};

export type MutationCreateDefaultMultipleDriversAttendancesArgs = {
  condition: CreateDefaultMultipleDriversAttendanceInput;
};

export type MutationUpdateDriverAttendancesArgs = {
  driverAttendances: Array<UpdateDriverAttendanceInput>;
};

export type MutationDeleteDriverAttendancesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationUpdateDriverSequenceArgs = {
  driverSequence: UpdateDriverSequenceInput;
};

export type MutationCreateDriverArgs = {
  driver: CreateDriverInput;
};

export type MutationCreateDriversArgs = {
  drivers: Array<CreateDriverInput>;
};

export type MutationUpdateDriverArgs = {
  driver: UpdateDriverInput;
};

export type MutationUpdateDriversArgs = {
  drivers: Array<UpdateDriverInput>;
};

export type MutationDeleteDriverArgs = {
  id: Scalars['ID'];
};

export type MutationCreateErpOrderArgs = {
  erpOrder: CreateErpOrderInput;
};

export type MutationUpdateErpOrderArgs = {
  erpOrder: UpdateErpOrderInput;
};

export type MutationDeleteErpOrderByOrderIdArgs = {
  orderId: Scalars['ID'];
  includeFollowingRecurringOrders?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateGenerationSitesArgs = {
  generationSites: Array<CreateGenerationSiteInput>;
};

export type MutationUpdateGenerationSitesArgs = {
  generationSites: Array<UpdateGenerationSiteInput>;
};

export type MutationUpdateHolidayRulesArgs = {
  holidayRules: Array<UpdateHolidayRuleInput>;
};

export type MutationUpdateOfficeSettingsArgs = {
  officeSettings: Array<UpdateOfficeSettingInput>;
};

export type MutationCreateOrderAcceptanceCheckArgs = {
  orderAcceptanceCheck: CreateOrderAcceptanceCheckInput;
};

export type MutationUpdateOrdersArgs = {
  orders: Array<UpdateOrderInput>;
};

export type MutationCreateOrdersArgs = {
  orders: Array<CreateOrderInput>;
};

export type MutationCancelOrderArgs = {
  id: Scalars['ID'];
};

export type MutationActivateOrderArgs = {
  id: Scalars['ID'];
};

export type MutationPostponeOrdersArgs = {
  orders: Array<PostponeOrderInput>;
};

export type MutationUpdateOrderCheckItemArgs = {
  orderCheckItemId: Scalars['ID'];
  checked: Scalars['Boolean'];
};

export type MutationUpdatePackingPlacementsArgs = {
  generationSiteId: Scalars['ID'];
  packingPlacements: Array<UpdatePackingPlacementInput>;
};

export type MutationAcceptReservationArgs = {
  acceptReservationInput: AcceptReservationInput;
};

export type MutationCancelReservationArgs = {
  cancelReservationInput: CancelReservationInput;
};

export type MutationPushBackReservationFromCancelledToWaitingReviewArgs = {
  pushBackReservationFromCancelledToWaitingReviewInput: PushBackReservationFromCancelledToWaitingReviewInput;
};

export type MutationUpdateReservationDeadlineArgs = {
  date: Scalars['Iso8601Date'];
  status: ReservationDeadlineStatus;
};

export type MutationCreateRoutingRegulationArgs = {
  routingRegulation: CreateRoutingRegulationInput;
};

export type MutationUpdateRoutingRegulationArgs = {
  routingRegulation: UpdateRoutingRegulationInput;
};

export type MutationDeleteRoutingRegulationArgs = {
  id: Scalars['ID'];
};

export type MutationUpdatePublishingStatusOfScheduleArgs = {
  publishingStatusInput: UpdatePublishingStatusOfScheduleInput;
};

export type MutationCreateSchedulesArgs = {
  schedules: Array<CreateScheduleInput>;
};

export type MutationCreateTaskTypeArgs = {
  task: CreateTaskTypeInput;
};

export type MutationUpdateTaskTypeArgs = {
  task: UpdateTaskTypeInput;
};

export type MutationCreateWasteTypeArgs = {
  wasteType: CreateWasteTypeInput;
};

export type MutationUpdateWasteTypeArgs = {
  wasteType: UpdateWasteTypeInput;
};

export type NoCandidateDatesError = {
  __typename?: 'NoCandidateDatesError';
  name: Scalars['String'];
};

export type OfficeSetting = {
  __typename?: 'OfficeSetting';
  id: Scalars['ID'];
  defaultDurationAtBaseSite: Scalars['Second'];
  defaultDurationAtGenerationSite: Scalars['Second'];
  defaultDurationAtDisposalSite: Scalars['Second'];
  defaultRestPeriodStartOfGenerationSite: Maybe<Scalars['Second']>;
  defaultRestPeriodEndOfGenerationSite: Maybe<Scalars['Second']>;
  defaultRestPeriodStartOfDisposalSite: Maybe<Scalars['Second']>;
  defaultRestPeriodEndOfDisposalSite: Maybe<Scalars['Second']>;
  defaultBannedDriverIdsAtGenerationSite: Array<Scalars['ID']>;
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: Scalars['Boolean'];
  defaultAvoidHighways: Scalars['Boolean'];
  isPackingPlacementManagementEnabled: Scalars['Boolean'];
  isFixedArrivalTimeReportEnabled: Scalars['Boolean'];
  defaultIsFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: Scalars['Second'];
  isOnlineReservationEnabled: Scalars['Boolean'];
  isPublishingStatusOfScheduleManagementEnabled: Scalars['Boolean'];
  canPrintSchedules: Scalars['Boolean'];
  canUseErp: Scalars['Boolean'];
  useCsvImport: Scalars['Boolean'];
  useCsvExport: Scalars['Boolean'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  code: Maybe<Scalars['String']>;
  plan: OrderPlan;
  client: Client;
  orderGroup: OrderGroup;
  generationSite: GenerationSite;
  generationSiteTasks: Array<GenerationSiteTask>;
  irregularTasks: Array<IrregularTask>;
  durationAtGenerationSite: Scalars['Second'];
  routeCollectionAllowed: Scalars['Boolean'];
  assignedDisposalSitesAndType: OrderDisposalSitesAndType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: Array<OrderAssignableDriver>;
  assignableCars: Array<Car>;
  assignableCarTypes: Array<SimpleCarType>;
  carNum: Scalars['Int'];
  minAssignedDriverNum: Scalars['Int'];
  maxAssignedDriverNum: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  noteForAssignedDriver: Maybe<Scalars['String']>;
  attachments: Maybe<Array<UploadedFile>>;
  noteFromReservation: Maybe<Scalars['String']>;
  avoidHighways: Scalars['Boolean'];
  status: OrderStatus;
  recurringSettings: Maybe<OrderRecurringSettings>;
  serialNumber: Scalars['Int'];
  preloadStatus: PreloadStatus;
  assignAssignableDriversOnUnloadDate: Maybe<Scalars['Boolean']>;
  fixedArrivalTime: Maybe<Scalars['Second']>;
  isFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: Scalars['Second'];
  orderCheckItems: Array<OrderCheckItem>;
  routingGroup: Maybe<OrderRoutingGroup>;
  fixedDisplayOnReservation: Scalars['Boolean'];
  fixedDisplayOnReservationName: Maybe<Scalars['String']>;
  schedulingPriority: OrderSchedulingPriority;
  createdVia: OrderCreatedVia;
  createdBy: User;
  createdAt: Scalars['Iso8601DateTime'];
  updatedBy: User;
  updatedAt: Scalars['Iso8601DateTime'];
};

export type OrderAcceptanceCheck = {
  __typename?: 'OrderAcceptanceCheck';
  id: Scalars['ID'];
  acceptanceData: Maybe<Scalars['OrderAcceptanceCheckJsonObject']>;
  ordersNum: Maybe<Scalars['Int']>;
  estimatedFinishAt: Maybe<Scalars['Iso8601DateTime']>;
  status: OrderAcceptanceCheckStatus;
};

export enum OrderAcceptanceCheckStatus {
  Waiting = 'Waiting',
  Running = 'Running',
  Finished = 'Finished',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

export type OrderAssignableDriver = {
  __typename?: 'OrderAssignableDriver';
  driverType: DriverType;
  driver: Driver;
};

export type OrderAssignedDisposalSite = {
  __typename?: 'OrderAssignedDisposalSite';
  disposalSite: DisposalSite;
  priority: Maybe<OrderDisposalSitePriority>;
  disposablePeriodStart: Scalars['Second'];
  disposablePeriodEnd: Scalars['Second'];
  durationAtEntrance: Scalars['Second'];
};

export type OrderCandidateDate = {
  __typename?: 'OrderCandidateDate';
  date: Scalars['Iso8601Date'];
  collectablePeriodTemplateName: Maybe<Scalars['String']>;
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  unloadDate: Maybe<Scalars['Iso8601Date']>;
};

export type OrderCandidateDateInput = {
  date: Scalars['Iso8601Date'];
  collectablePeriodTemplateName: Maybe<Scalars['String']>;
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  unloadDate: Maybe<Scalars['Iso8601Date']>;
};

export type OrderCheckItem = {
  __typename?: 'OrderCheckItem';
  id: Scalars['ID'];
  checkItem: CheckItem;
  checked: Scalars['Boolean'];
  checkedAt: Maybe<Scalars['Iso8601DateTime']>;
  checkedBy: Maybe<User>;
};

export enum OrderCreatedVia {
  Haisyagasira = 'Haisyagasira',
  Reservation = 'Reservation',
}

export type OrderDefault = {
  __typename?: 'OrderDefault';
  defaultCollectablePeriodTemplate: CollectablePeriodTemplate;
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: Scalars['Boolean'];
  defaultIsFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: Scalars['Second'];
};

export enum OrderDisposalSiteAssignmentType {
  PrioritySingle = 'PrioritySingle',
  NonSequentialMultiple = 'NonSequentialMultiple',
  SequentialMultiple = 'SequentialMultiple',
}

export enum OrderDisposalSitePriority {
  High = 'High',
  Low = 'Low',
}

export type OrderDisposalSitesAndType = {
  __typename?: 'OrderDisposalSitesAndType';
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  orderDisposalSites: Array<OrderAssignedDisposalSite>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

export type OrderFixedPlan = {
  __typename?: 'OrderFixedPlan';
  date: Scalars['Iso8601Date'];
  collectablePeriodTemplateName: Maybe<Scalars['String']>;
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  unloadDate: Maybe<Scalars['Iso8601Date']>;
};

export type OrderGroup = {
  __typename?: 'OrderGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  colorName: OrderGroupColor;
};

export enum OrderGroupColor {
  Primary = 'Primary',
  Red = 'Red',
  Blue = 'Blue',
  Green = 'Green',
  White = 'White',
}

export enum OrderKind {
  IndustrialWasteCollection = 'IndustrialWasteCollection',
  Other = 'Other',
  OtherCollection = 'OtherCollection',
}

export type OrderMutationResult = {
  __typename?: 'OrderMutationResult';
  id: Scalars['ID'];
  generationSiteTaskIds: Array<Scalars['ID']>;
  irregularTaskIds: Array<Scalars['ID']>;
  serialNumber: Scalars['Int'];
};

export type OrderPlan = {
  __typename?: 'OrderPlan';
  fixed: Maybe<OrderFixedPlan>;
  candidateDates: Maybe<Array<OrderCandidateDate>>;
};

export type OrderPlanInput = {
  candidateDates: Array<OrderCandidateDateInput>;
};

export type OrderRecurringSettings = {
  __typename?: 'OrderRecurringSettings';
  type: OrderRecurringSettingsType;
  step: Scalars['Int'];
  daysOfWeek: Maybe<Array<DayOfWeek>>;
  daysOfMonth: Maybe<Array<DayOfMonth>>;
  includeNationalHolidays: Scalars['Boolean'];
  startAt: Scalars['Iso8601Date'];
  endAt: Maybe<Scalars['Iso8601Date']>;
};

export enum OrderRecurringSettingsType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export type OrderRoutingGroup = {
  __typename?: 'OrderRoutingGroup';
  orderIds: Array<Scalars['ID']>;
};

export type OrderRoutingGroupInput = {
  orderIds: Array<Scalars['ID']>;
};

export enum OrderSchedulingPriority {
  High = 'High',
  None = 'None',
}

export enum OrderStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export enum OvertimeWorkType {
  None = 'None',
  Both = 'Both',
  AvailableInEarlyTime = 'AvailableInEarlyTime',
  AvailableInLateTime = 'AvailableInLateTime',
}

export type PackingPlacement = {
  __typename?: 'PackingPlacement';
  id: Scalars['ID'];
  containerType: ContainerType;
  num: Scalars['Int'];
  lastAllocatedAt: Scalars['Iso8601DateTime'];
};

export type PackingPlacementsAtGenerationSite = IPackingPlacementsAtSite & {
  __typename?: 'PackingPlacementsAtGenerationSite';
  generationSite: GenerationSite;
  packingPlacements: Array<PackingPlacement>;
  lastAllocatedAt: Maybe<Scalars['Iso8601DateTime']>;
  managementStartedOn: Maybe<Scalars['Iso8601Date']>;
};

export type PackingPlacementsByKeywordsCondition = {
  keywords: Maybe<Array<Scalars['String']>>;
  containerTypeIds: Maybe<Array<Scalars['ID']>>;
  leftSince: Maybe<Scalars['Iso8601Date']>;
  orderBy: PackingPlacementsByKeywordsOrder;
};

export type PackingPlacementsByKeywordsConnection = {
  __typename?: 'PackingPlacementsByKeywordsConnection';
  edges: Array<PackingPlacementsEdge>;
  nodes: Array<IPackingPlacementsAtSite>;
  pageInfo: PageInfo;
};

export enum PackingPlacementsByKeywordsOrder {
  LastAllocatedAtAsc = 'LastAllocatedAtAsc',
  LastAllocatedAtDesc = 'LastAllocatedAtDesc',
}

export type PackingPlacementsEdge = {
  __typename?: 'PackingPlacementsEdge';
  node: IPackingPlacementsAtSite;
  cursor: Scalars['String'];
};

export type PackingStyle = {
  __typename?: 'PackingStyle';
  id: Scalars['ID'];
  code: PackingStyleCode;
  name: Scalars['String'];
  defaultUnitName: Scalars['String'];
};

export enum PackingStyleCode {
  Code01 = 'Code01',
  Code02 = 'Code02',
  Code03 = 'Code03',
  Code04 = 'Code04',
  Code05 = 'Code05',
  Code06 = 'Code06',
  Code07 = 'Code07',
  Code08 = 'Code08',
  Code09 = 'Code09',
}

export type PackingStyleTaskTypeDefault = {
  __typename?: 'PackingStyleTaskTypeDefault';
  id: Scalars['ID'];
  packingStyleId: Scalars['ID'];
  taskType: TaskType;
  duration: Scalars['Second'];
  status: ContainerTypeTaskTypeStatus;
  isProportionalToCount: Scalars['Boolean'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  endCursor: Maybe<Scalars['String']>;
};

export type PostponeOrderInput = {
  id: Scalars['ID'];
  date: Scalars['Iso8601Date'];
};

export type PostponeOrderMutationError = {
  __typename?: 'PostponeOrderMutationError';
  errors: Array<PostponeOrderMutationErrorTypes>;
};

export type PostponeOrderMutationErrorTypes = NoCandidateDatesError;

export type PostponeOrderResult = Order | PostponeOrderMutationError;

export enum PreloadStatus {
  NotAllowed = 'NotAllowed',
  Forced = 'Forced',
  Modifiable = 'Modifiable',
}

export enum PublishingStatus {
  Published = 'Published',
  Unpublished = 'Unpublished',
}

export type PushBackReservationFromCancelledToWaitingReviewInput = {
  id: Scalars['ID'];
};

export type PushBackReservationFromCancelledToWaitingReviewResult = {
  __typename?: 'PushBackReservationFromCancelledToWaitingReviewResult';
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  unreadAnnouncements: Array<Announcement>;
  attendancesByDateRange: Array<Attendance>;
  attendancesByIds: Array<Attendance>;
  allBaseSites: Array<BaseSite>;
  allBaseTaskTypes: Array<BaseTaskType>;
  carAttendancesByDateRange: Array<CarAttendance>;
  allCarTypes: Array<CarType>;
  allCars: Array<Car>;
  carsByIds: Array<Car>;
  allCheckItems: Array<CheckItem>;
  clientsByIds: Array<Client>;
  clientsByKeywords: ClientsByKeywordsConnection;
  allCollectablePeriodTemplates: Array<CollectablePeriodTemplate>;
  allContainerTypeTaskTypes: Array<ContainerTypeTaskType>;
  allPackingStyleTaskTypeDefaults: Array<PackingStyleTaskTypeDefault>;
  allContainerTypes: Array<ContainerType>;
  containerTypesByIds: Array<ContainerType>;
  allDisposalSites: Array<DisposalSite>;
  disposalSiteAttendancesByDateRange: Array<DisposalSiteAttendance>;
  allDriverAttendanceTemplates: Array<DriverAttendanceTemplate>;
  driverAttendanceTemplatesByIds: Array<DriverAttendanceTemplate>;
  allDriverAttendancesByDateRange: Array<DriverAttendance>;
  driverAttendancesByDateRange: Array<DriverAttendance>;
  allDrivers: Array<Driver>;
  driversByIds: Array<Driver>;
  allTransportationClients: Array<ErpClient>;
  erpOrderByOrderId: Maybe<ErpOrder>;
  generationSiteTasksByIds: Array<GenerationSiteTask>;
  generationSitesByIds: Array<GenerationSite>;
  generationSitesByKeywords: GenerationSitesByKeywordsConnection;
  holidayRule: HolidayRule;
  irregularTasksByIds: Array<IrregularTask>;
  allJwnetWasteMasters: Array<JwnetWasteMaster>;
  officeSetting: OfficeSetting;
  orderAcceptanceChecksByIds: Array<OrderAcceptanceCheck>;
  orderDefaultByGenerationSiteId: OrderDefault;
  allOrderGroups: Array<OrderGroup>;
  ordersByDate: Array<Order>;
  ordersByIds: Array<Order>;
  /** @deprecated Use searchOrders */
  latestOrders: Array<Order>;
  searchOrders: SearchedOrdersConnection;
  routableOrders: Array<Order>;
  validateOrders: Array<Scalars['OrderValidationJsonObject']>;
  packingPlacementsByKeywords: PackingPlacementsByKeywordsConnection;
  allPackingStyles: Array<PackingStyle>;
  reservationsByDate: Array<Reservation>;
  cancelledReservationsByDate: Array<Reservation>;
  waitingReviewReservationDatesByDateRange: Array<Scalars['Iso8601Date']>;
  reservationDeadlineByDate: ReservationDeadline;
  allRoutingRegulations: Array<RoutingRegulationGenerationSite>;
  routingRegulationsByIds: Array<RoutingRegulationGenerationSite>;
  latestSchedules: Array<Schedule>;
  schedulesByIds: Array<Schedule>;
  scheduleByScheduleRequest: ScheduleByScheduleRequestResult;
  revertSchedule: RevertScheduleResult;
  currentSession: Session;
  allTaskTypes: Array<TaskType>;
  users: UsersConnection;
  usersByIds: Array<User>;
  allWasteTypes: Array<WasteType>;
  allDisplayedWasteTypes: Array<WasteType>;
  wasteTypesByKeywords: Array<WasteType>;
  availableWasteTypeCode: Scalars['String'];
};

export type QueryAttendancesByDateRangeArgs = {
  condition: AttendancesByDateRangeInput;
};

export type QueryAttendancesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryCarAttendancesByDateRangeArgs = {
  condition: CarAttendancesByDateRangeInput;
};

export type QueryCarsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryClientsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryClientsByKeywordsArgs = {
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: ClientsByKeywordsCondition;
};

export type QueryContainerTypesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryDisposalSiteAttendancesByDateRangeArgs = {
  condition: DisposalSiteAttendancesByDateRangeInput;
};

export type QueryDriverAttendanceTemplatesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryAllDriverAttendancesByDateRangeArgs = {
  condition: AllDriverAttendancesByDateRangeInput;
};

export type QueryDriverAttendancesByDateRangeArgs = {
  condition: DriverAttendancesByDateRangeInput;
};

export type QueryDriversByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryErpOrderByOrderIdArgs = {
  orderId: Scalars['ID'];
};

export type QueryGenerationSiteTasksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGenerationSitesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGenerationSitesByKeywordsArgs = {
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: GenerationSitesByKeywordsCondition;
};

export type QueryIrregularTasksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryOrderAcceptanceChecksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryOrderDefaultByGenerationSiteIdArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersByDateArgs = {
  date: Scalars['Iso8601Date'];
};

export type QueryOrdersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryLatestOrdersArgs = {
  condition: LatestOrdersInput;
};

export type QuerySearchOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  condition: SearchOrdersInput;
  first: Scalars['Int'];
};

export type QueryRoutableOrdersArgs = {
  condition: RoutableOrdersInput;
};

export type QueryValidateOrdersArgs = {
  orders: Array<CreateOrderInput>;
};

export type QueryPackingPlacementsByKeywordsArgs = {
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: PackingPlacementsByKeywordsCondition;
};

export type QueryReservationsByDateArgs = {
  date: Scalars['Iso8601Date'];
};

export type QueryCancelledReservationsByDateArgs = {
  date: Scalars['Iso8601Date'];
};

export type QueryWaitingReviewReservationDatesByDateRangeArgs = {
  condition: WaitingReviewReservationDatesByDateRangeInput;
};

export type QueryReservationDeadlineByDateArgs = {
  date: Scalars['Iso8601Date'];
};

export type QueryRoutingRegulationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryLatestSchedulesArgs = {
  condition: LatestSchedulesInput;
};

export type QuerySchedulesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryScheduleByScheduleRequestArgs = {
  scheduleByScheduleRequestInput: ScheduleByScheduleRequestInput;
};

export type QueryRevertScheduleArgs = {
  revertScheduleInput: RevertScheduleInput;
};

export type QueryUsersArgs = {
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: Maybe<UsersCondition>;
};

export type QueryUsersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryWasteTypesByKeywordsArgs = {
  keywords: Array<Scalars['String']>;
};

export type QueryAvailableWasteTypeCodeArgs = {
  code: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  candidateDates: Array<CandidateDate>;
  note: Maybe<Scalars['String']>;
  sourceOrderNote: Maybe<Scalars['String']>;
  client: Client;
  orderGroup: OrderGroup;
  generationSite: GenerationSite;
  generationSiteTasks: Array<ReservationGenerationSiteTask>;
  durationAtGenerationSite: Scalars['Second'];
  routeCollectionAllowed: Scalars['Boolean'];
  assignedDisposalSitesAndType: OrderDisposalSitesAndType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: Array<OrderAssignableDriver>;
  assignableCars: Array<Car>;
  assignableCarTypes: Array<SimpleCarType>;
  carNum: Scalars['Int'];
  minAssignedDriverNum: Scalars['Int'];
  maxAssignedDriverNum: Scalars['Int'];
  avoidHighways: Scalars['Boolean'];
  isFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: Scalars['Second'];
};

export type ReservationDeadline = {
  __typename?: 'ReservationDeadline';
  date: Scalars['Iso8601Date'];
  reservable: Scalars['Boolean'];
  deadlineUpdatable: Scalars['Boolean'];
};

export enum ReservationDeadlineStatus {
  Opened = 'Opened',
  Closed = 'Closed',
}

export type ReservationGenerationSiteTask = {
  __typename?: 'ReservationGenerationSiteTask';
  taskType: TaskType;
  wasteType: Maybe<WasteType>;
  containerType: ContainerType;
  containerNum: Scalars['Float'];
};

export type RevertScheduleInput = {
  requestId: Scalars['ID'];
  attendanceId: Scalars['ID'];
  orderGroupId: Scalars['ID'];
  scheduleRequest: Scalars['ScheduleRequestJsonObject'];
  driverId: Scalars['ID'];
};

export type RevertScheduleResult = {
  __typename?: 'RevertScheduleResult';
  requestId: Scalars['ID'];
  affectedDriverIds: Array<Scalars['ID']>;
  scheduleResponse: Scalars['ScheduleResponseJsonObject'];
};

export type RoutableOrdersInput = {
  orderId: Maybe<Scalars['ID']>;
  orderGroupId: Scalars['ID'];
  date: Scalars['Iso8601Date'];
};

export type RoutingRegulation = RoutingRegulationGenerationSite;

export type RoutingRegulationGenerationSite = IRoutingRegulation & {
  __typename?: 'RoutingRegulationGenerationSite';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdBy: User;
  updatedBy: User;
  createdAt: Scalars['Iso8601DateTime'];
  updatedAt: Scalars['Iso8601DateTime'];
  generationSites: Array<GenerationSite>;
  optionalGenerationSites: Array<GenerationSite>;
  preserveGenerationSiteSequence: Scalars['Boolean'];
};

export type RoutingRegulationMutationError = {
  __typename?: 'RoutingRegulationMutationError';
  errors: Array<RoutingRegulationMutationErrorTypes>;
};

export type RoutingRegulationMutationErrorTypes = DuplicatedNameError;

export type RoutingRegulationMutationResultTypes = RoutingRegulationGenerationSite | RoutingRegulationMutationError;

export enum RoutingRegulationType {
  GenerationSite = 'GenerationSite',
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  attendanceId: Scalars['ID'];
  orderGroupId: Scalars['ID'];
  status: ScheduleStatus;
  scheduleResponse: Maybe<Scalars['ScheduleResponseJsonObject']>;
  scheduleResources: Maybe<ScheduleResources>;
  creatingScheduleStartedAt: Maybe<Scalars['Iso8601DateTime']>;
  isPublished: Scalars['Boolean'];
};

export type ScheduleByScheduleRequestInput = {
  requestId: Scalars['ID'];
  attendanceId: Scalars['ID'];
  orderGroupId: Scalars['ID'];
  scheduleRequest: Scalars['ScheduleRequestJsonObject'];
};

export type ScheduleByScheduleRequestResult = {
  __typename?: 'ScheduleByScheduleRequestResult';
  requestId: Scalars['ID'];
  scheduleResponse: Scalars['ScheduleResponseJsonObject'];
};

export type ScheduleResources = {
  __typename?: 'ScheduleResources';
  drivers: Array<Driver>;
  cars: Array<Car>;
  carTypes: Array<CarType>;
  disposalSites: Array<DisposalSite>;
  baseSites: Array<BaseSite>;
  containerTypes: Array<ContainerType>;
  orders: Array<Order>;
};

export enum ScheduleStatus {
  Waiting = 'Waiting',
  Running = 'Running',
  Finished = 'Finished',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  FailedByConflict = 'FailedByConflict',
}

export enum SchedulingPermissionType {
  EditMaster = 'EditMaster',
  EditOrder = 'EditOrder',
  Read = 'Read',
}

export type SearchedOrdersConnection = {
  __typename?: 'SearchedOrdersConnection';
  edges: Array<OrderEdge>;
  nodes: Array<Order>;
  pageInfo: PageInfo;
};

export type SearchOrdersInput = {
  clientId: Maybe<Scalars['ID']>;
  generationSiteIds: Array<Scalars['ID']>;
  startAt: Maybe<Scalars['Iso8601Date']>;
  endAt: Maybe<Scalars['Iso8601Date']>;
  createdStartAt: Maybe<Scalars['Iso8601Date']>;
  createdEndAt: Maybe<Scalars['Iso8601Date']>;
  note: Maybe<Scalars['String']>;
  wasteTypeId: Maybe<Scalars['ID']>;
  createdById: Maybe<Scalars['ID']>;
  includeCancelled: Maybe<Scalars['Boolean']>;
  includeFollowingRecurringOrder: Maybe<Scalars['Boolean']>;
  onlyFixedDisplayOnReservation: Maybe<Scalars['Boolean']>;
};

export type Session = {
  __typename?: 'Session';
  user: User;
  officeId: Scalars['ID'];
  officeName: Scalars['String'];
};

export type SimpleCarType = {
  __typename?: 'SimpleCarType';
  id: Scalars['ID'];
  name: Scalars['String'];
  orderGroup: OrderGroup;
};

export enum SiteType {
  GenerationSite = 'GenerationSite',
  DisposalSite = 'DisposalSite',
  BaseSite = 'BaseSite',
}

export enum SoftDeleteStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export type TaskType = {
  __typename?: 'TaskType';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: TaskTypeStatus;
  isProminent: Scalars['Boolean'];
  baseTaskType: BaseTaskType;
  bannedDrivers: Array<Driver>;
};

export type TaskTypeMutationError = {
  __typename?: 'TaskTypeMutationError';
  errors: Array<TaskTypeMutationErrorTypes>;
};

export type TaskTypeMutationErrorTypes = DuplicatedNameError;

export type TaskTypeMutationResultTypes = TaskType | TaskTypeMutationError;

export enum TaskTypeStatus {
  Displayed = 'Displayed',
  NotDisplayed = 'NotDisplayed',
}

export type UpdateBaseSiteInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  hasCars: Scalars['Boolean'];
  hasContainers: Scalars['Boolean'];
};

export type UpdateCarAttendanceInput = {
  id: Scalars['ID'];
  attendanceId: Scalars['ID'];
  carId: Scalars['ID'];
  carAttendanceType: CarAttendanceType;
  periodStart: Maybe<Scalars['Second']>;
  periodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
};

export type UpdateCarInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  carTypeId: Scalars['ID'];
  baseSiteId: Scalars['ID'];
  code: Maybe<Scalars['String']>;
};

export type UpdateCarTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  orderGroupId: Scalars['ID'];
  drivingSpeedCoefficient: Scalars['Float'];
  durationOfBoarding: Scalars['Second'];
  durationOfAlighting: Scalars['Second'];
  loadableContainerTypes: Array<CreateCarTypeContainerTypeInput>;
};

export type UpdateClientInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  note: Maybe<Scalars['String']>;
  status: SoftDeleteStatus;
  packingStyleReservationSettings: Maybe<Array<ClientPackingStyleReservationSettingInput>>;
};

export type UpdateCollectablePeriodTemplateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  collectablePeriodStart: Maybe<Scalars['Second']>;
  collectablePeriodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export type UpdateContainerTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  packingStyleId: Scalars['ID'];
  unitName: Scalars['String'];
  transformingCarTypeId: Maybe<Scalars['ID']>;
  status: SoftDeleteStatus;
  reservationSiteEnabled: Maybe<Scalars['Boolean']>;
};

export type UpdateContainerTypeTaskTypeInput = {
  id: Scalars['ID'];
  values: Maybe<UpdateContainerTypeTaskTypeInputValue>;
};

export type UpdateContainerTypeTaskTypeInputValue = {
  duration: Maybe<Scalars['Second']>;
  status: Maybe<ContainerTypeTaskTypeStatus>;
  isProportionalToCount: Maybe<Scalars['Boolean']>;
};

export type UpdateDisposalSiteAttendanceInput = {
  id: Scalars['ID'];
  periodStart: Maybe<Scalars['Second']>;
  periodEnd: Maybe<Scalars['Second']>;
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: Scalars['Int'];
  note: Maybe<Scalars['String']>;
};

export type UpdateDisposalSiteInput = {
  id: Scalars['ID'];
  clientId: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  disposablePeriodStart: Scalars['Second'];
  disposablePeriodEnd: Scalars['Second'];
  durationAtEntrance: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  acceptanceLimit: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  isAddressComplete: Scalars['Boolean'];
  temporalAcceptanceInterval: Maybe<Scalars['Second']>;
  temporalAcceptanceLimit: Maybe<Scalars['Int']>;
};

export type UpdateDriverAttendanceInput = {
  id: Scalars['ID'];
  attendanceId: Scalars['ID'];
  driverId: Scalars['ID'];
  primaryCarId: Scalars['ID'];
  forceRidePrimaryCar: Maybe<Scalars['Boolean']>;
  templateName: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
};

export type UpdateDriverAttendanceTemplateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  regularWorkPeriodStart: Scalars['Second'];
  regularWorkPeriodEnd: Scalars['Second'];
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: Scalars['Second'];
  defaultForceRidePrimaryCar: Scalars['Boolean'];
};

export type UpdateDriverInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  employmentStatus: EmploymentStatus;
  defaultPrimaryCarId: Maybe<Scalars['ID']>;
  licensedCarTypeIds: Array<Scalars['ID']>;
  defaultAttendanceTemplateId: Maybe<Scalars['ID']>;
  status: Maybe<SoftDeleteStatus>;
  code: Maybe<Scalars['String']>;
};

export type UpdateDriverSequenceInput = {
  sortedDriverIds: Array<Scalars['ID']>;
};

export type UpdateErpOrderInput = {
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  transportationClientId: Scalars['ID'];
  withDisposalItemFromScheduling: Scalars['Boolean'];
  note: Maybe<Scalars['String']>;
  erpOrderItems: Array<CreateErpOrderItemInput>;
  includeFollowingRecurringOrders: Maybe<Scalars['Boolean']>;
};

export type UpdateGenerationSiteInput = {
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  name: Scalars['String'];
  nameRuby: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  address4: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  bannedDriverIds: Array<Scalars['ID']>;
  defaultAssignedDriverId: Maybe<Scalars['ID']>;
  defaultAssignedDisposalSiteId: Maybe<Scalars['ID']>;
  defaultAssignableCarIds: Array<Scalars['ID']>;
  defaultAssignableCarTypeIds: Array<Scalars['ID']>;
  defaultAvoidHighways: Scalars['Boolean'];
  defaultDurationAtEntrance: Scalars['Second'];
  defaultCollectablePeriodTemplateId: Maybe<Scalars['ID']>;
  defaultCollectablePeriodStart: Maybe<Scalars['Second']>;
  defaultCollectablePeriodEnd: Maybe<Scalars['Second']>;
  defaultRouteCollectionAllowed: Maybe<Scalars['Boolean']>;
  defaultPreloadStatus: Maybe<PreloadStatus>;
  restPeriodStart: Maybe<Scalars['Second']>;
  restPeriodEnd: Maybe<Scalars['Second']>;
  note: Maybe<Scalars['String']>;
  noteForOffice: Maybe<Scalars['String']>;
  attachmentsToAdd: Maybe<Array<Scalars['Upload']>>;
  attachmentsToRemove: Maybe<Array<Scalars['String']>>;
  isAddressComplete: Scalars['Boolean'];
  defaultIsFixedArrivalTimeReportNeeded: Maybe<Scalars['Boolean']>;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: Scalars['Second'];
  status: SoftDeleteStatus;
};

export type UpdateHolidayRuleInput = {
  id: Scalars['ID'];
  monday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  friday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  nationalHoliday: Scalars['Boolean'];
};

export type UpdateOfficeSettingInput = {
  id: Scalars['ID'];
  defaultDurationAtBaseSite: Scalars['Second'];
  defaultDurationAtGenerationSite: Scalars['Second'];
  defaultDurationAtDisposalSite: Scalars['Second'];
  defaultRestPeriodStartOfGenerationSite: Maybe<Scalars['Second']>;
  defaultRestPeriodEndOfGenerationSite: Maybe<Scalars['Second']>;
  defaultRestPeriodStartOfDisposalSite: Maybe<Scalars['Second']>;
  defaultRestPeriodEndOfDisposalSite: Maybe<Scalars['Second']>;
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: Scalars['Boolean'];
};

export type UpdateOrderInput = {
  id: Scalars['ID'];
  plan: OrderPlanInput;
  orderGroupId: Scalars['ID'];
  generationSiteId: Scalars['ID'];
  generationSiteTasks: Maybe<Array<CreateGenerationSiteTaskInput>>;
  irregularTasks: Maybe<Array<CreateIrregularTaskInput>>;
  durationAtGenerationSite: Scalars['Second'];
  routeCollectionAllowed: Scalars['Boolean'];
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignableCarIds: Array<Scalars['ID']>;
  assignableCarTypeIds: Array<Scalars['ID']>;
  carNum: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  noteForAssignedDriver: Maybe<Scalars['String']>;
  attachmentsToAdd: Maybe<Array<Scalars['Upload']>>;
  attachmentsToRemove: Maybe<Array<Scalars['String']>>;
  avoidHighways: Scalars['Boolean'];
  status: OrderStatus;
  includeFollowingRecurringOrders: Maybe<Scalars['Boolean']>;
  preloadStatus: PreloadStatus;
  assignAssignableDriversOnUnloadDate: Maybe<Scalars['Boolean']>;
  fixedArrivalTime: Maybe<Scalars['Second']>;
  isFixedArrivalTimeReportNeeded: Scalars['Boolean'];
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: Scalars['Second'];
  checkItemIds: Array<Scalars['ID']>;
  routingGroup: Maybe<OrderRoutingGroupInput>;
  fixedDisplayOnReservation: Scalars['Boolean'];
  fixedDisplayOnReservationName: Maybe<Scalars['String']>;
  schedulingPriority: OrderSchedulingPriority;
};

export type UpdatePackingPlacementInput = {
  containerTypeId: Scalars['ID'];
  num: Scalars['Int'];
  lastAllocatedAt: Maybe<Scalars['Iso8601DateTime']>;
};

export type UpdatePackingStyleTaskTypeDefaultInput = {
  id: Scalars['ID'];
  duration: Scalars['Second'];
  status: ContainerTypeTaskTypeStatus;
  isProportionalToCount: Scalars['Boolean'];
};

export type UpdatePublishingStatusOfScheduleInput = {
  orderGroupId: Scalars['ID'];
  date: Scalars['Iso8601Date'];
  status: PublishingStatus;
};

export type UpdateRoutingRegulationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  routingRegulationType: RoutingRegulationType;
  generationSiteIds: Maybe<Array<Scalars['ID']>>;
  optionalGenerationSiteIds: Maybe<Array<Scalars['ID']>>;
  preserveGenerationSiteSequence: Maybe<Scalars['Boolean']>;
};

export type UpdateTaskTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  bannedDriverIds: Maybe<Array<Scalars['ID']>>;
};

export type UpdateWasteTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: WasteTypeStatus;
  isProminent: Scalars['Boolean'];
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  filename: Scalars['String'];
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export type UsersCondition = {
  keywords: Maybe<Array<Scalars['String']>>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  totalCount: Scalars['Int'];
  edges: Array<UserEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export enum Vacancy {
  Available = 'Available',
  Limited = 'Limited',
  None = 'None',
}

export type WaitingReviewReservationDatesByDateRangeInput = {
  start: Scalars['Iso8601Date'];
  end: Scalars['Iso8601Date'];
};

export enum WasteCategory {
  Waste = 'Waste',
  Valuable = 'Valuable',
  GeneralWaste = 'GeneralWaste',
}

export type WasteType = {
  __typename?: 'WasteType';
  id: Scalars['ID'];
  category: WasteCategory;
  code: Scalars['String'];
  jwnetWasteMaster: Maybe<JwnetWasteMaster>;
  name: Scalars['String'];
  status: WasteTypeStatus;
  isProminent: Scalars['Boolean'];
  createdBy: User;
  createdAt: Scalars['Iso8601DateTime'];
  updatedBy: User;
  updatedAt: Scalars['Iso8601DateTime'];
};

export type WasteTypeCreateError = {
  __typename?: 'WasteTypeCreateError';
  errors: Array<WasteTypeCreateErrorTypes>;
};

export type WasteTypeCreateErrorTypes = DuplicatedCodeError;

export type WasteTypeCreateResultTypes = WasteType | WasteTypeCreateError;

export enum WasteTypeStatus {
  Displayed = 'Displayed',
  NotDisplayed = 'NotDisplayed',
}

export enum WeekdayOrdinal {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Last = 'Last',
}

export type AnnouncementFieldsFragment = { __typename?: 'Announcement' } & Pick<
  Announcement,
  'id' | 'subject' | 'body' | 'publishedSince' | 'publishedUntil' | 'isRead'
>;

export type AttendanceFieldsFragment = { __typename?: 'Attendance' } & Pick<Attendance, 'id' | 'date'>;

export type BaseSiteFieldsFragment = { __typename?: 'BaseSite' } & Pick<
  BaseSite,
  | 'id'
  | 'name'
  | 'nameRuby'
  | 'zipCode'
  | 'address1'
  | 'address2'
  | 'address3'
  | 'address4'
  | 'latitude'
  | 'longitude'
  | 'hasCars'
  | 'hasContainers'
  | 'createdAt'
> & { createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'> };

export type CarTypeFieldsFragment = { __typename?: 'CarType' } & Pick<
  CarType,
  'id' | 'name' | 'drivingSpeedCoefficient' | 'durationOfBoarding' | 'durationOfAlighting'
> & {
    orderGroup: { __typename?: 'OrderGroup' } & OrderGroupFieldsFragment;
    loadableContainerTypes: Array<
      { __typename?: 'CarTypeContainerType' } & Pick<
        CarTypeContainerType,
        | 'id'
        | 'emptyCount'
        | 'emptyCountOccupation'
        | 'lowerTierFullCount'
        | 'upperTierFullCount'
        | 'fullCountOccupation'
        | 'isUpperTierAvailable'
      > & { containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment }
    >;
  };

export type CarFieldsFragment = { __typename?: 'Car' } & Pick<Car, 'id' | 'name' | 'status' | 'enabledOn' | 'code'> & {
    simpleCarType: { __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment;
    baseSite: { __typename?: 'BaseSite' } & BaseSiteFieldsFragment;
  };

export type CheckItemFieldsFragment = { __typename?: 'CheckItem' } & Pick<
  CheckItem,
  'id' | 'name' | 'default' | 'status'
>;

export type ClientFieldsFragment = { __typename?: 'Client' } & Pick<
  Client,
  | 'id'
  | 'code'
  | 'name'
  | 'nameRuby'
  | 'note'
  | 'createdAt'
  | 'updatedAt'
  | 'generationSiteNum'
  | 'status'
  | 'signInUrl'
  | 'reservationSiteEnabled'
> & {
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    updatedBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    packingStyleReservationSettings: Array<
      { __typename?: 'ClientPackingStyleReservationSetting' } & Pick<
        ClientPackingStyleReservationSetting,
        'visible'
      > & {
          packingStyle: { __typename?: 'PackingStyle' } & Pick<
            PackingStyle,
            'id' | 'code' | 'name' | 'defaultUnitName'
          >;
          containerTypeReservationSettings: Array<
            { __typename?: 'ClientContainerTypeReservationSetting' } & Pick<
              ClientContainerTypeReservationSetting,
              'visible'
            > & { containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment }
          >;
        }
    >;
  };

export type CollectablePeriodTemplateFieldsFragment = { __typename?: 'CollectablePeriodTemplate' } & Pick<
  CollectablePeriodTemplate,
  'id' | 'name' | 'collectablePeriodStart' | 'collectablePeriodEnd' | 'note' | 'isDefault'
>;

export type ContainerTypeFieldsFragment = { __typename?: 'ContainerType' } & Pick<
  ContainerType,
  'id' | 'name' | 'unitName' | 'status' | 'reservationSiteEnabled'
> & {
    packingStyle: { __typename?: 'PackingStyle' } & Pick<PackingStyle, 'id' | 'code' | 'name' | 'defaultUnitName'>;
    transformingCarType: Maybe<{ __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment>;
  };

export type DisposalSiteFieldsFragment = { __typename?: 'DisposalSite' } & Pick<
  DisposalSite,
  | 'id'
  | 'code'
  | 'name'
  | 'nameRuby'
  | 'zipCode'
  | 'address1'
  | 'address2'
  | 'address3'
  | 'address4'
  | 'latitude'
  | 'longitude'
  | 'disposablePeriodStart'
  | 'disposablePeriodEnd'
  | 'durationAtEntrance'
  | 'restPeriodStart'
  | 'restPeriodEnd'
  | 'acceptanceLimit'
  | 'temporalAcceptanceInterval'
  | 'temporalAcceptanceLimit'
  | 'note'
  | 'isAddressComplete'
  | 'createdAt'
> & {
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    client: Maybe<{ __typename?: 'Client' } & ClientFieldsFragment>;
  };

export type DriverAttendanceTemplateFieldsFragment = { __typename?: 'DriverAttendanceTemplate' } & Pick<
  DriverAttendanceTemplate,
  | 'id'
  | 'name'
  | 'regularWorkPeriodStart'
  | 'regularWorkPeriodEnd'
  | 'restPeriodStart'
  | 'restPeriodEnd'
  | 'overtimeWorkType'
  | 'overtimeWorkableDuration'
  | 'defaultForceRidePrimaryCar'
>;

export type DriverAttendanceFieldsFragment = { __typename?: 'DriverAttendance' } & Pick<
  DriverAttendance,
  | 'id'
  | 'forceRidePrimaryCar'
  | 'templateName'
  | 'regularWorkPeriodStart'
  | 'regularWorkPeriodEnd'
  | 'restPeriodStart'
  | 'restPeriodEnd'
  | 'overtimeWorkType'
  | 'overtimeWorkableDuration'
> & {
    attendance: { __typename?: 'Attendance' } & AttendanceFieldsFragment;
    driver: { __typename?: 'Driver' } & DriverFieldsFragment;
    primaryCar: { __typename?: 'Car' } & CarFieldsFragment;
  };

export type DriverFieldsFragment = { __typename?: 'Driver' } & Pick<
  Driver,
  'id' | 'name' | 'nameRuby' | 'employmentStatus' | 'status' | 'signInUrl' | 'code'
> & {
    defaultPrimaryCar: Maybe<{ __typename?: 'Car' } & CarFieldsFragment>;
    licensedCarTypes: Array<{ __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment>;
    defaultAttendanceTemplate: Maybe<
      { __typename?: 'DriverAttendanceTemplate' } & DriverAttendanceTemplateFieldsFragment
    >;
  };

export type GenerationSiteTaskFieldsFragment = { __typename?: 'GenerationSiteTask' } & Pick<
  GenerationSiteTask,
  'id' | 'orderId' | 'containerNum'
> & {
    taskType: { __typename?: 'TaskType' } & TaskTypeFieldsFragment;
    wasteType: Maybe<{ __typename?: 'WasteType' } & WasteTypeFieldsFragment>;
    containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment;
  };

export type GenerationSiteFieldsFragment = { __typename?: 'GenerationSite' } & Pick<
  GenerationSite,
  | 'id'
  | 'code'
  | 'name'
  | 'nameRuby'
  | 'zipCode'
  | 'address1'
  | 'address2'
  | 'address3'
  | 'address4'
  | 'latitude'
  | 'longitude'
  | 'defaultAvoidHighways'
  | 'defaultDurationAtEntrance'
  | 'restPeriodStart'
  | 'restPeriodEnd'
  | 'note'
  | 'isAddressComplete'
  | 'createdAt'
  | 'status'
  | 'defaultCollectablePeriodStart'
  | 'defaultCollectablePeriodEnd'
  | 'defaultRouteCollectionAllowed'
  | 'defaultPreloadStatus'
  | 'defaultIsFixedArrivalTimeReportNeeded'
  | 'defaultMarginTypeOfFixedArrivalTime'
  | 'defaultMarginOfFixedArrivalTime'
  | 'noteForOffice'
> & {
    client: { __typename?: 'Client' } & ClientFieldsFragment;
    bannedDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
    defaultAssignedDriver: Maybe<{ __typename?: 'Driver' } & DriverFieldsFragment>;
    defaultAssignedDisposalSite: Maybe<{ __typename?: 'DisposalSite' } & DisposalSiteFieldsFragment>;
    defaultAssignableCars: Array<{ __typename?: 'Car' } & CarFieldsFragment>;
    defaultAssignableCarTypes: Array<{ __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment>;
    attachments: Maybe<Array<{ __typename?: 'UploadedFile' } & Pick<UploadedFile, 'filename' | 'url'>>>;
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    defaultCollectablePeriodTemplate: Maybe<
      { __typename?: 'CollectablePeriodTemplate' } & CollectablePeriodTemplateFieldsFragment
    >;
  };

export type IrregularTaskFieldsFragment = { __typename?: 'IrregularTask' } & Pick<
  IrregularTask,
  'id' | 'orderId' | 'name' | 'durationAtGenerationSite' | 'durationAtDisposalSite' | 'skipDisposalSite'
>;

export type OrderGroupFieldsFragment = { __typename?: 'OrderGroup' } & Pick<OrderGroup, 'id' | 'name' | 'colorName'>;

export type OrderFieldsFragment = { __typename?: 'Order' } & Pick<
  Order,
  | 'id'
  | 'code'
  | 'serialNumber'
  | 'durationAtGenerationSite'
  | 'routeCollectionAllowed'
  | 'driverAssignmentType'
  | 'carNum'
  | 'minAssignedDriverNum'
  | 'maxAssignedDriverNum'
  | 'note'
  | 'noteForAssignedDriver'
  | 'noteFromReservation'
  | 'avoidHighways'
  | 'status'
  | 'preloadStatus'
  | 'assignAssignableDriversOnUnloadDate'
  | 'fixedArrivalTime'
  | 'isFixedArrivalTimeReportNeeded'
  | 'marginTypeOfFixedArrivalTime'
  | 'marginOfFixedArrivalTime'
  | 'fixedDisplayOnReservation'
  | 'fixedDisplayOnReservationName'
  | 'schedulingPriority'
  | 'createdVia'
  | 'createdAt'
  | 'updatedAt'
> & {
    orderGroup: { __typename?: 'OrderGroup' } & OrderGroupFieldsFragment;
    plan: { __typename?: 'OrderPlan' } & {
      fixed: Maybe<
        { __typename?: 'OrderFixedPlan' } & Pick<
          OrderFixedPlan,
          'date' | 'collectablePeriodTemplateName' | 'collectablePeriodStart' | 'collectablePeriodEnd' | 'unloadDate'
        >
      >;
      candidateDates: Maybe<
        Array<
          { __typename?: 'OrderCandidateDate' } & Pick<
            OrderCandidateDate,
            'date' | 'collectablePeriodTemplateName' | 'collectablePeriodStart' | 'collectablePeriodEnd' | 'unloadDate'
          >
        >
      >;
    };
    client: { __typename?: 'Client' } & ClientFieldsFragment;
    generationSite: { __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment;
    generationSiteTasks: Array<{ __typename?: 'GenerationSiteTask' } & GenerationSiteTaskFieldsFragment>;
    irregularTasks: Array<{ __typename?: 'IrregularTask' } & IrregularTaskFieldsFragment>;
    assignedDisposalSitesAndType: { __typename?: 'OrderDisposalSitesAndType' } & Pick<
      OrderDisposalSitesAndType,
      'disposalSiteAssignmentType'
    > & {
        orderDisposalSites: Array<
          { __typename?: 'OrderAssignedDisposalSite' } & Pick<
            OrderAssignedDisposalSite,
            'durationAtEntrance' | 'priority' | 'disposablePeriodStart' | 'disposablePeriodEnd'
          > & { disposalSite: { __typename?: 'DisposalSite' } & DisposalSiteFieldsFragment }
        >;
      };
    assignableDrivers: Array<
      { __typename?: 'OrderAssignableDriver' } & Pick<OrderAssignableDriver, 'driverType'> & {
          driver: { __typename?: 'Driver' } & DriverFieldsFragment;
        }
    >;
    assignableCars: Array<{ __typename?: 'Car' } & CarFieldsFragment>;
    assignableCarTypes: Array<{ __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment>;
    attachments: Maybe<Array<{ __typename?: 'UploadedFile' } & Pick<UploadedFile, 'filename' | 'url'>>>;
    recurringSettings: Maybe<
      { __typename?: 'OrderRecurringSettings' } & Pick<
        OrderRecurringSettings,
        'type' | 'step' | 'daysOfWeek' | 'includeNationalHolidays' | 'startAt' | 'endAt'
      > & {
          daysOfMonth: Maybe<Array<{ __typename?: 'DayOfMonth' } & Pick<DayOfMonth, 'weekdayOrdinal' | 'dayOfWeek'>>>;
        }
    >;
    orderCheckItems: Array<
      { __typename?: 'OrderCheckItem' } & Pick<OrderCheckItem, 'id' | 'checked' | 'checkedAt'> & {
          checkItem: { __typename?: 'CheckItem' } & Pick<CheckItem, 'id' | 'name' | 'default' | 'status'>;
          checkedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>;
        }
    >;
    routingGroup: Maybe<{ __typename?: 'OrderRoutingGroup' } & Pick<OrderRoutingGroup, 'orderIds'>>;
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    updatedBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
  };

export type PackingPlacementFieldsFragment = { __typename?: 'PackingPlacement' } & Pick<
  PackingPlacement,
  'id' | 'num' | 'lastAllocatedAt'
> & { containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment };

export type ReservationGenerationSiteTaskFieldsFragment = { __typename?: 'ReservationGenerationSiteTask' } & Pick<
  ReservationGenerationSiteTask,
  'containerNum'
> & {
    taskType: { __typename?: 'TaskType' } & TaskTypeFieldsFragment;
    wasteType: Maybe<{ __typename?: 'WasteType' } & WasteTypeFieldsFragment>;
    containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment;
  };

export type ReservationFieldsFragment = { __typename?: 'Reservation' } & Pick<
  Reservation,
  | 'id'
  | 'note'
  | 'sourceOrderNote'
  | 'durationAtGenerationSite'
  | 'routeCollectionAllowed'
  | 'driverAssignmentType'
  | 'carNum'
  | 'minAssignedDriverNum'
  | 'maxAssignedDriverNum'
  | 'avoidHighways'
  | 'isFixedArrivalTimeReportNeeded'
  | 'marginTypeOfFixedArrivalTime'
  | 'marginOfFixedArrivalTime'
> & {
    candidateDates: Array<
      { __typename?: 'CandidateDate' } & Pick<
        CandidateDate,
        'date' | 'vacancy' | 'collectablePeriodTemplateName' | 'collectablePeriodStart' | 'collectablePeriodEnd'
      >
    >;
    orderGroup: { __typename?: 'OrderGroup' } & OrderGroupFieldsFragment;
    client: { __typename?: 'Client' } & ClientFieldsFragment;
    generationSite: { __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment;
    generationSiteTasks: Array<
      { __typename?: 'ReservationGenerationSiteTask' } & ReservationGenerationSiteTaskFieldsFragment
    >;
    assignedDisposalSitesAndType: { __typename?: 'OrderDisposalSitesAndType' } & Pick<
      OrderDisposalSitesAndType,
      'disposalSiteAssignmentType'
    > & {
        orderDisposalSites: Array<
          { __typename?: 'OrderAssignedDisposalSite' } & Pick<
            OrderAssignedDisposalSite,
            'durationAtEntrance' | 'priority' | 'disposablePeriodStart' | 'disposablePeriodEnd'
          > & { disposalSite: { __typename?: 'DisposalSite' } & DisposalSiteFieldsFragment }
        >;
      };
    assignableDrivers: Array<
      { __typename?: 'OrderAssignableDriver' } & Pick<OrderAssignableDriver, 'driverType'> & {
          driver: { __typename?: 'Driver' } & DriverFieldsFragment;
        }
    >;
    assignableCars: Array<{ __typename?: 'Car' } & CarFieldsFragment>;
    assignableCarTypes: Array<{ __typename?: 'SimpleCarType' } & SimpleCarTypeFieldsFragment>;
  };

export type RoutingRegulationFieldsFragment = { __typename?: 'RoutingRegulationGenerationSite' } & Pick<
  RoutingRegulationGenerationSite,
  'id' | 'name' | 'createdAt' | 'updatedAt' | 'preserveGenerationSiteSequence'
> & {
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    updatedBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    generationSites: Array<{ __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment>;
    optionalGenerationSites: Array<{ __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment>;
  };

export type SimpleCarTypeFieldsFragment = { __typename?: 'SimpleCarType' } & Pick<SimpleCarType, 'id' | 'name'> & {
    orderGroup: { __typename?: 'OrderGroup' } & OrderGroupFieldsFragment;
  };

export type TaskTypeFieldsFragment = { __typename: 'TaskType' } & Pick<
  TaskType,
  'id' | 'name' | 'status' | 'isProminent'
> & {
    baseTaskType: { __typename: 'BaseTaskType' } & Pick<BaseTaskType, 'id' | 'name' | 'orderKind' | 'siteType'>;
    bannedDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
  };

export type WasteTypeFieldsFragment = { __typename?: 'WasteType' } & Pick<
  WasteType,
  'id' | 'category' | 'code' | 'name' | 'status' | 'isProminent' | 'createdAt' | 'updatedAt'
> & {
    jwnetWasteMaster: Maybe<
      { __typename?: 'JwnetWasteMaster' } & Pick<
        JwnetWasteMaster,
        'id' | 'firstCategoryName' | 'secondCategoryName' | 'thirdCategoryName' | 'code' | 'isUsable'
      >
    >;
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    updatedBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
  };

export type CreateAttendancesMutationVariables = Exact<{
  attendances: Array<CreateAttendanceInput> | CreateAttendanceInput;
}>;

export type CreateAttendancesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createAttendances'>;

type BaseSiteMutationResultBaseSiteFragment = { __typename: 'BaseSite' } & BaseSiteFieldsFragment;

type BaseSiteMutationResultBaseSiteMutationErrorFragment = { __typename: 'BaseSiteMutationError' } & {
  errors: Array<
    | ({ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>)
    | ({ __typename: 'HasCarsMustBeTrueError' } & Pick<HasCarsMustBeTrueError, 'name'>)
    | { __typename: 'HasCarsOrHasContainersMustBeTrueError' }
  >;
};

export type BaseSiteMutationResultFragment =
  | BaseSiteMutationResultBaseSiteFragment
  | BaseSiteMutationResultBaseSiteMutationErrorFragment;

export type CreateBaseSiteMutationVariables = Exact<{
  baseSite: CreateBaseSiteInput;
}>;

export type CreateBaseSiteMutation = { __typename?: 'Mutation' } & {
  createBaseSite:
    | ({ __typename?: 'BaseSite' } & BaseSiteMutationResultBaseSiteFragment)
    | ({ __typename?: 'BaseSiteMutationError' } & BaseSiteMutationResultBaseSiteMutationErrorFragment);
};

export type UpdateBaseSiteMutationVariables = Exact<{
  baseSite: UpdateBaseSiteInput;
}>;

export type UpdateBaseSiteMutation = { __typename?: 'Mutation' } & {
  updateBaseSite:
    | ({ __typename?: 'BaseSite' } & BaseSiteMutationResultBaseSiteFragment)
    | ({ __typename?: 'BaseSiteMutationError' } & BaseSiteMutationResultBaseSiteMutationErrorFragment);
};

export type UpdateCarAttendanceMutationVariables = Exact<{
  carAttendance: UpdateCarAttendanceInput;
}>;

export type UpdateCarAttendanceMutation = { __typename?: 'Mutation' } & {
  updateCarAttendance: { __typename?: 'CarAttendance' } & Pick<
    CarAttendance,
    'id' | 'carId' | 'carAttendanceType' | 'periodStart' | 'periodEnd' | 'note' | 'unavailableReason'
  > & { attendance: { __typename?: 'Attendance' } & Pick<Attendance, 'id' | 'date'> };
};

type CarTypeMutationResultCarTypeFragment = { __typename: 'CarType' } & CarTypeFieldsFragment;

type CarTypeMutationResultCarTypeMutationErrorFragment = { __typename: 'CarTypeMutationError' } & {
  errors: Array<{ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>>;
};

export type CarTypeMutationResultFragment =
  | CarTypeMutationResultCarTypeFragment
  | CarTypeMutationResultCarTypeMutationErrorFragment;

export type CreateCarTypeMutationVariables = Exact<{
  carType: CreateCarTypeInput;
}>;

export type CreateCarTypeMutation = { __typename?: 'Mutation' } & {
  createCarType:
    | ({ __typename?: 'CarType' } & CarTypeMutationResultCarTypeFragment)
    | ({ __typename?: 'CarTypeMutationError' } & CarTypeMutationResultCarTypeMutationErrorFragment);
};

export type UpdateCarTypeMutationVariables = Exact<{
  carType: UpdateCarTypeInput;
}>;

export type UpdateCarTypeMutation = { __typename?: 'Mutation' } & {
  updateCarType:
    | ({ __typename?: 'CarType' } & CarTypeMutationResultCarTypeFragment)
    | ({ __typename?: 'CarTypeMutationError' } & CarTypeMutationResultCarTypeMutationErrorFragment);
};

type CarMutationResultCarFragment = { __typename: 'Car' } & CarFieldsFragment;

type CarMutationResultCarMutationErrorFragment = { __typename: 'CarMutationError' } & {
  errors: Array<
    ({ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>) | { __typename: 'DuplicatedCodeError' }
  >;
};

export type CarMutationResultFragment = CarMutationResultCarFragment | CarMutationResultCarMutationErrorFragment;

export type CreateCarMutationVariables = Exact<{
  car: CreateCarInput;
}>;

export type CreateCarMutation = { __typename?: 'Mutation' } & {
  createCar:
    | ({ __typename?: 'Car' } & CarMutationResultCarFragment)
    | ({ __typename?: 'CarMutationError' } & CarMutationResultCarMutationErrorFragment);
};

export type UpdateCarMutationVariables = Exact<{
  car: UpdateCarInput;
}>;

export type UpdateCarMutation = { __typename?: 'Mutation' } & {
  updateCar:
    | ({ __typename?: 'Car' } & CarMutationResultCarFragment)
    | ({ __typename?: 'CarMutationError' } & CarMutationResultCarMutationErrorFragment);
};

type CheckItemMutationResultCheckItemFragment = { __typename: 'CheckItem' } & CheckItemFieldsFragment;

type CheckItemMutationResultCheckItemMutationErrorFragment = { __typename: 'CheckItemMutationError' } & {
  errors: Array<{ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>>;
};

export type CheckItemMutationResultFragment =
  | CheckItemMutationResultCheckItemFragment
  | CheckItemMutationResultCheckItemMutationErrorFragment;

export type CreateCheckItemMutationVariables = Exact<{
  checkItem: CheckItemInput;
}>;

export type CreateCheckItemMutation = { __typename?: 'Mutation' } & {
  createCheckItem:
    | ({ __typename?: 'CheckItem' } & CheckItemMutationResultCheckItemFragment)
    | ({ __typename?: 'CheckItemMutationError' } & CheckItemMutationResultCheckItemMutationErrorFragment);
};

export type UpdateCheckItemMutationVariables = Exact<{
  id: Scalars['ID'];
  checkItem: CheckItemInput;
}>;

export type UpdateCheckItemMutation = { __typename?: 'Mutation' } & {
  updateCheckItem:
    | ({ __typename?: 'CheckItem' } & CheckItemMutationResultCheckItemFragment)
    | ({ __typename?: 'CheckItemMutationError' } & CheckItemMutationResultCheckItemMutationErrorFragment);
};

export type DeleteCheckItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCheckItemMutation = { __typename?: 'Mutation' } & {
  deleteCheckItem: { __typename?: 'CheckItem' } & CheckItemFieldsFragment;
};

export type UpdateCheckItemSequenceMutationVariables = Exact<{
  checkItemSequence: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateCheckItemSequenceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateCheckItemSequence'>;

export type ClientMutationResultFragment = { __typename?: 'ClientResult' } & {
  client: { __typename?: 'Client' } & ClientFieldsFragment;
};

export type CreateClientsMutationVariables = Exact<{
  clients: Array<CreateClientInput> | CreateClientInput;
}>;

export type CreateClientsMutation = { __typename?: 'Mutation' } & {
  createClients: Array<{ __typename?: 'ClientResult' } & ClientMutationResultFragment>;
};

export type UpdateClientsMutationVariables = Exact<{
  clients: Array<UpdateClientInput> | UpdateClientInput;
}>;

export type UpdateClientsMutation = { __typename?: 'Mutation' } & {
  updateClients: Array<{ __typename?: 'ClientResult' } & ClientMutationResultFragment>;
};

export type CreateCollectablePeriodTemplatesMutationVariables = Exact<{
  collectablePeriodTemplates: Array<CreateCollectablePeriodTemplateInput> | CreateCollectablePeriodTemplateInput;
}>;

export type CreateCollectablePeriodTemplatesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createCollectablePeriodTemplates'
>;

export type UpdateCollectablePeriodTemplatesMutationVariables = Exact<{
  collectablePeriodTemplates: Array<UpdateCollectablePeriodTemplateInput> | UpdateCollectablePeriodTemplateInput;
}>;

export type UpdateCollectablePeriodTemplatesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateCollectablePeriodTemplates'
>;

export type UpdateContainerTypeTaskTypesMutationVariables = Exact<{
  containerTypeTaskTypes: Array<UpdateContainerTypeTaskTypeInput> | UpdateContainerTypeTaskTypeInput;
}>;

export type UpdateContainerTypeTaskTypesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateContainerTypeTaskTypes'
>;

export type CreateContainerTypeMutationVariables = Exact<{
  containerType: CreateContainerTypeInput;
}>;

export type CreateContainerTypeMutation = { __typename?: 'Mutation' } & {
  createContainerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment;
};

export type UpdateContainerTypeMutationVariables = Exact<{
  containerType: UpdateContainerTypeInput;
}>;

export type UpdateContainerTypeMutation = { __typename?: 'Mutation' } & {
  updateContainerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment;
};

export type UpdateDisposalSiteAttendanceMutationVariables = Exact<{
  disposalSiteAttendance: UpdateDisposalSiteAttendanceInput;
}>;

export type UpdateDisposalSiteAttendanceMutation = { __typename?: 'Mutation' } & {
  updateDisposalSiteAttendance: { __typename?: 'DisposalSiteAttendance' } & Pick<DisposalSiteAttendance, 'id'>;
};

export type CreateDisposalSitesMutationVariables = Exact<{
  disposalSites: Array<CreateDisposalSiteInput> | CreateDisposalSiteInput;
}>;

export type CreateDisposalSitesMutation = { __typename?: 'Mutation' } & {
  createDisposalSites: Array<
    { __typename?: 'CreateDisposalSiteResult' } & Pick<CreateDisposalSiteResult, 'id' | 'createdAt'> & {
        createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
      }
  >;
};

export type UpdateDisposalSitesMutationVariables = Exact<{
  disposalSites: Array<UpdateDisposalSiteInput> | UpdateDisposalSiteInput;
}>;

export type UpdateDisposalSitesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateDisposalSites'>;

export type CreateDriverAttendanceTemplatesMutationVariables = Exact<{
  driverAttendanceTemplates: Array<CreateDriverAttendanceTemplateInput> | CreateDriverAttendanceTemplateInput;
}>;

export type CreateDriverAttendanceTemplatesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createDriverAttendanceTemplates'
>;

export type UpdateDriverAttendanceTemplatesMutationVariables = Exact<{
  driverAttendanceTemplates: Array<UpdateDriverAttendanceTemplateInput> | UpdateDriverAttendanceTemplateInput;
}>;

export type UpdateDriverAttendanceTemplatesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateDriverAttendanceTemplates'
>;

export type CreateDriverAttendancesMutationVariables = Exact<{
  driverAttendances: Array<CreateDriverAttendanceInput> | CreateDriverAttendanceInput;
}>;

export type CreateDriverAttendancesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createDriverAttendances'>;

export type UpdateDriverAttendancesMutationVariables = Exact<{
  driverAttendances: Array<UpdateDriverAttendanceInput> | UpdateDriverAttendanceInput;
}>;

export type UpdateDriverAttendancesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateDriverAttendances'>;

export type DeleteDriverAttendancesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteDriverAttendancesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteDriverAttendances'>;

export type CreateDefaultDriverAttendancesMutationVariables = Exact<{
  condition: CreateDefaultDriverAttendanceInput;
}>;

export type CreateDefaultDriverAttendancesMutation = { __typename?: 'Mutation' } & {
  createDefaultDriverAttendances: Array<{ __typename?: 'DriverAttendance' } & DriverAttendanceFieldsFragment>;
};

export type CreateDefaultMultipleDriversAttendancesMutationVariables = Exact<{
  condition: CreateDefaultMultipleDriversAttendanceInput;
}>;

export type CreateDefaultMultipleDriversAttendancesMutation = { __typename?: 'Mutation' } & {
  createDefaultMultipleDriversAttendances: { __typename?: 'CreateDefaultMultipleDriversAttendancesPayload' } & Pick<
    CreateDefaultMultipleDriversAttendancesPayload,
    'skippedDriverIds'
  >;
};

export type CreateDriversMutationVariables = Exact<{
  drivers: Array<CreateDriverInput> | CreateDriverInput;
}>;

export type CreateDriversMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createDrivers'>;

export type UpdateDriversMutationVariables = Exact<{
  drivers: Array<UpdateDriverInput> | UpdateDriverInput;
}>;

export type UpdateDriversMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateDrivers'>;

export type UpdateDriverSequenceMutationVariables = Exact<{
  driverSequence: UpdateDriverSequenceInput;
}>;

export type UpdateDriverSequenceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateDriverSequence'>;

export type ErpOrderFieldsFragment = { __typename?: 'ErpOrder' } & Pick<
  ErpOrder,
  'id' | 'orderId' | 'withDisposalItemFromScheduling' | 'note'
> & {
    transportationClient: { __typename?: 'ErpClient' } & Pick<ErpClient, 'id' | 'name'>;
    erpOrderItems: Array<
      { __typename?: 'ErpOrderItem' } & Pick<
        ErpOrderItem,
        'id' | 'generationSiteTaskId' | 'irregularTaskId' | 'apparentQuantity' | 'apparentQuantityUnit'
      >
    >;
  };

export type CreateErpOrderMutationVariables = Exact<{
  erpOrder: CreateErpOrderInput;
}>;

export type CreateErpOrderMutation = { __typename?: 'Mutation' } & {
  createErpOrder: { __typename?: 'ErpOrder' } & ErpOrderFieldsFragment;
};

export type UpdateErpOrderMutationVariables = Exact<{
  erpOrder: UpdateErpOrderInput;
}>;

export type UpdateErpOrderMutation = { __typename?: 'Mutation' } & {
  updateErpOrder: { __typename?: 'ErpOrder' } & ErpOrderFieldsFragment;
};

export type DeleteErpOrderByOrderIdMutationVariables = Exact<{
  orderId: Scalars['ID'];
  includeFollowingRecurringOrders?: Maybe<Scalars['Boolean']>;
}>;

export type DeleteErpOrderByOrderIdMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteErpOrderByOrderId'>;

export type CreateGenerationSitesMutationVariables = Exact<{
  generationSites: Array<CreateGenerationSiteInput> | CreateGenerationSiteInput;
}>;

export type CreateGenerationSitesMutation = { __typename?: 'Mutation' } & {
  createGenerationSites: Array<
    { __typename?: 'CreateGenerationSiteResult' } & Pick<CreateGenerationSiteResult, 'id' | 'createdAt'>
  >;
};

export type UpdateGenerationSitesMutationVariables = Exact<{
  generationSites: Array<UpdateGenerationSiteInput> | UpdateGenerationSiteInput;
}>;

export type UpdateGenerationSitesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateGenerationSites'>;

export type UpdateHolidayRulesMutationVariables = Exact<{
  holidayRules: Array<UpdateHolidayRuleInput> | UpdateHolidayRuleInput;
}>;

export type UpdateHolidayRulesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateHolidayRules'>;

export type UpdateOfficeSettingsMutationVariables = Exact<{
  officeSettings: Array<UpdateOfficeSettingInput> | UpdateOfficeSettingInput;
}>;

export type UpdateOfficeSettingsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateOfficeSettings'>;

export type CreateOrderAcceptanceCheckMutationVariables = Exact<{
  orderAcceptanceCheck: CreateOrderAcceptanceCheckInput;
}>;

export type CreateOrderAcceptanceCheckMutation = { __typename?: 'Mutation' } & {
  createOrderAcceptanceCheck: { __typename?: 'CreateOrderAcceptanceCheckResult' } & Pick<
    CreateOrderAcceptanceCheckResult,
    'id' | 'acceptanceData' | 'status'
  >;
};

type PostponeOrderResultOrderFragment = { __typename: 'Order' } & OrderFieldsFragment;

type PostponeOrderResultPostponeOrderMutationErrorFragment = { __typename: 'PostponeOrderMutationError' } & {
  errors: Array<{ __typename: 'NoCandidateDatesError' } & Pick<NoCandidateDatesError, 'name'>>;
};

export type PostponeOrderResultFragment =
  | PostponeOrderResultOrderFragment
  | PostponeOrderResultPostponeOrderMutationErrorFragment;

export type UpdateOrdersMutationVariables = Exact<{
  orders: Array<UpdateOrderInput> | UpdateOrderInput;
}>;

export type UpdateOrdersMutation = { __typename?: 'Mutation' } & {
  updateOrders: Array<
    { __typename?: 'OrderMutationResult' } & Pick<
      OrderMutationResult,
      'id' | 'generationSiteTaskIds' | 'irregularTaskIds' | 'serialNumber'
    >
  >;
};

export type CreateOrdersMutationVariables = Exact<{
  orders: Array<CreateOrderInput> | CreateOrderInput;
}>;

export type CreateOrdersMutation = { __typename?: 'Mutation' } & {
  createOrders: Array<
    { __typename?: 'OrderMutationResult' } & Pick<
      OrderMutationResult,
      'id' | 'generationSiteTaskIds' | 'irregularTaskIds' | 'serialNumber'
    >
  >;
};

export type CancelOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelOrderMutation = { __typename?: 'Mutation' } & {
  cancelOrder: { __typename?: 'Order' } & OrderFieldsFragment;
};

export type ActivateOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActivateOrderMutation = { __typename?: 'Mutation' } & {
  activateOrder: { __typename?: 'Order' } & OrderFieldsFragment;
};

export type PostponeOrdersMutationVariables = Exact<{
  orders: Array<PostponeOrderInput> | PostponeOrderInput;
}>;

export type PostponeOrdersMutation = { __typename?: 'Mutation' } & {
  postponeOrders: Array<
    | ({ __typename?: 'Order' } & PostponeOrderResultOrderFragment)
    | ({ __typename?: 'PostponeOrderMutationError' } & PostponeOrderResultPostponeOrderMutationErrorFragment)
  >;
};

export type UpdateOrderCheckItemMutationVariables = Exact<{
  orderCheckItemId: Scalars['ID'];
  checked: Scalars['Boolean'];
}>;

export type UpdateOrderCheckItemMutation = { __typename?: 'Mutation' } & {
  updateOrderCheckItem: { __typename?: 'Order' } & OrderFieldsFragment;
};

export type UpdatePackingPlacementMutationVariables = Exact<{
  generationSiteId: Scalars['ID'];
  packingPlacements: Array<UpdatePackingPlacementInput> | UpdatePackingPlacementInput;
}>;

export type UpdatePackingPlacementMutation = { __typename?: 'Mutation' } & {
  updatePackingPlacements: Array<{ __typename?: 'PackingPlacement' } & PackingPlacementFieldsFragment>;
};

export type UpdatePackingStyleTaskTypeDefaultsMutationVariables = Exact<{
  packingStyleTaskTypeDefaults: Array<UpdatePackingStyleTaskTypeDefaultInput> | UpdatePackingStyleTaskTypeDefaultInput;
}>;

export type UpdatePackingStyleTaskTypeDefaultsMutation = { __typename?: 'Mutation' } & {
  updatePackingStyleTaskTypeDefaults: Array<
    { __typename?: 'PackingStyleTaskTypeDefault' } & Pick<
      PackingStyleTaskTypeDefault,
      'id' | 'packingStyleId' | 'duration' | 'status' | 'isProportionalToCount'
    > & { taskType: { __typename?: 'TaskType' } & TaskTypeFieldsFragment }
  >;
};

export type AcceptReservationMutationVariables = Exact<{
  acceptReservationInput: AcceptReservationInput;
}>;

export type AcceptReservationMutation = { __typename?: 'Mutation' } & {
  acceptReservation: { __typename?: 'AcceptReservationMutationResult' } & Pick<
    AcceptReservationMutationResult,
    'id' | 'orderId'
  >;
};

export type CancelReservationMutationVariables = Exact<{
  cancelReservationInput: CancelReservationInput;
}>;

export type CancelReservationMutation = { __typename?: 'Mutation' } & {
  cancelReservation: { __typename?: 'CancelReservationMutationResult' } & Pick<CancelReservationMutationResult, 'id'>;
};

export type PushBackReservationFromCancelledToWaitingReviewMutationVariables = Exact<{
  pushBackReservationFromCancelledToWaitingReviewInput: PushBackReservationFromCancelledToWaitingReviewInput;
}>;

export type PushBackReservationFromCancelledToWaitingReviewMutation = { __typename?: 'Mutation' } & {
  pushBackReservationFromCancelledToWaitingReview: {
    __typename?: 'PushBackReservationFromCancelledToWaitingReviewResult';
  } & Pick<PushBackReservationFromCancelledToWaitingReviewResult, 'id'>;
};

export type UpdateReservationDeadlineMutationVariables = Exact<{
  date: Scalars['Iso8601Date'];
  status: ReservationDeadlineStatus;
}>;

export type UpdateReservationDeadlineMutation = { __typename?: 'Mutation' } & {
  updateReservationDeadline: { __typename?: 'ReservationDeadline' } & Pick<
    ReservationDeadline,
    'reservable' | 'deadlineUpdatable'
  >;
};

type RoutingRegulationMutationResultRoutingRegulationGenerationSiteFragment = {
  __typename: 'RoutingRegulationGenerationSite';
} & RoutingRegulationFieldsFragment;

type RoutingRegulationMutationResultRoutingRegulationMutationErrorFragment = {
  __typename: 'RoutingRegulationMutationError';
} & { errors: Array<{ __typename?: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>> };

export type RoutingRegulationMutationResultFragment =
  | RoutingRegulationMutationResultRoutingRegulationGenerationSiteFragment
  | RoutingRegulationMutationResultRoutingRegulationMutationErrorFragment;

export type CreateRoutingRegulationMutationVariables = Exact<{
  routingRegulation: CreateRoutingRegulationInput;
}>;

export type CreateRoutingRegulationMutation = { __typename?: 'Mutation' } & {
  createRoutingRegulation:
    | ({
        __typename?: 'RoutingRegulationGenerationSite';
      } & RoutingRegulationMutationResultRoutingRegulationGenerationSiteFragment)
    | ({
        __typename?: 'RoutingRegulationMutationError';
      } & RoutingRegulationMutationResultRoutingRegulationMutationErrorFragment);
};

export type UpdateRoutingRegulationMutationVariables = Exact<{
  routingRegulation: UpdateRoutingRegulationInput;
}>;

export type UpdateRoutingRegulationMutation = { __typename?: 'Mutation' } & {
  updateRoutingRegulation:
    | ({
        __typename?: 'RoutingRegulationGenerationSite';
      } & RoutingRegulationMutationResultRoutingRegulationGenerationSiteFragment)
    | ({
        __typename?: 'RoutingRegulationMutationError';
      } & RoutingRegulationMutationResultRoutingRegulationMutationErrorFragment);
};

export type DeleteRoutingRegulationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteRoutingRegulationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteRoutingRegulation'>;

export type CreateSchedulesMutationVariables = Exact<{
  schedules: Array<CreateScheduleInput> | CreateScheduleInput;
}>;

export type CreateSchedulesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createSchedules'>;

export type UpdatePublishingStatusOfScheduleMutationVariables = Exact<{
  publishingStatusInput: UpdatePublishingStatusOfScheduleInput;
}>;

export type UpdatePublishingStatusOfScheduleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updatePublishingStatusOfSchedule'
>;

export type CreateTaskTypeMutationVariables = Exact<{
  taskType: CreateTaskTypeInput;
}>;

export type CreateTaskTypeMutation = { __typename?: 'Mutation' } & {
  createTaskType:
    | ({ __typename: 'TaskType' } & Pick<TaskType, 'id' | 'name' | 'status' | 'isProminent'> & {
          baseTaskType: { __typename?: 'BaseTaskType' } & Pick<BaseTaskType, 'id'>;
          bannedDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
        })
    | ({ __typename: 'TaskTypeMutationError' } & {
        errors: Array<{ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>>;
      });
};

export type UpdateTaskTypeMutationVariables = Exact<{
  taskType: UpdateTaskTypeInput;
}>;

export type UpdateTaskTypeMutation = { __typename?: 'Mutation' } & {
  updateTaskType:
    | ({ __typename: 'TaskType' } & Pick<TaskType, 'id' | 'name' | 'status' | 'isProminent'> & {
          baseTaskType: { __typename?: 'BaseTaskType' } & Pick<BaseTaskType, 'id'>;
          bannedDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
        })
    | ({ __typename: 'TaskTypeMutationError' } & {
        errors: Array<{ __typename: 'DuplicatedNameError' } & Pick<DuplicatedNameError, 'name'>>;
      });
};

export type CreateWasteTypeMutationVariables = Exact<{
  wasteType: CreateWasteTypeInput;
}>;

export type CreateWasteTypeMutation = { __typename?: 'Mutation' } & {
  createWasteType:
    | ({ __typename: 'WasteType' } & WasteTypeFieldsFragment)
    | ({ __typename: 'WasteTypeCreateError' } & {
        errors: Array<{ __typename: 'DuplicatedCodeError' } & Pick<DuplicatedCodeError, 'name'>>;
      });
};

export type UpdateWasteTypeMutationVariables = Exact<{
  wasteType: UpdateWasteTypeInput;
}>;

export type UpdateWasteTypeMutation = { __typename?: 'Mutation' } & {
  updateWasteType: { __typename?: 'WasteType' } & WasteTypeFieldsFragment;
};

export type UnreadAnnouncementsQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadAnnouncementsQuery = { __typename?: 'Query' } & {
  unreadAnnouncements: Array<{ __typename?: 'Announcement' } & AnnouncementFieldsFragment>;
};

export type AttendancesByDateRangeQueryVariables = Exact<{
  condition: AttendancesByDateRangeInput;
}>;

export type AttendancesByDateRangeQuery = { __typename?: 'Query' } & {
  attendancesByDateRange: Array<{ __typename?: 'Attendance' } & AttendanceFieldsFragment>;
};

export type AttendancesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AttendancesByIdsQuery = { __typename?: 'Query' } & {
  attendancesByIds: Array<{ __typename?: 'Attendance' } & AttendanceFieldsFragment>;
};

export type AllBaseSitesQueryVariables = Exact<{ [key: string]: never }>;

export type AllBaseSitesQuery = { __typename?: 'Query' } & {
  allBaseSites: Array<{ __typename?: 'BaseSite' } & BaseSiteFieldsFragment>;
};

export type AllBaseTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllBaseTaskTypesQuery = { __typename?: 'Query' } & {
  allBaseTaskTypes: Array<
    { __typename: 'BaseTaskType' } & Pick<BaseTaskType, 'id' | 'name' | 'orderKind' | 'siteType'>
  >;
};

export type CarAttendancesByDateRangeQueryVariables = Exact<{
  condition: CarAttendancesByDateRangeInput;
}>;

export type CarAttendancesByDateRangeQuery = { __typename?: 'Query' } & {
  carAttendancesByDateRange: Array<
    { __typename?: 'CarAttendance' } & Pick<
      CarAttendance,
      'id' | 'carId' | 'carAttendanceType' | 'periodStart' | 'periodEnd' | 'note' | 'unavailableReason'
    > & { attendance: { __typename?: 'Attendance' } & Pick<Attendance, 'id' | 'date'> }
  >;
};

export type AllCarTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCarTypesQuery = { __typename?: 'Query' } & {
  allCarTypes: Array<{ __typename: 'CarType' } & CarTypeFieldsFragment>;
};

export type AllCarsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCarsQuery = { __typename?: 'Query' } & { allCars: Array<{ __typename: 'Car' } & CarFieldsFragment> };

export type CarsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type CarsByIdsQuery = { __typename?: 'Query' } & { carsByIds: Array<{ __typename: 'Car' } & CarFieldsFragment> };

export type AllCheckItemsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCheckItemsQuery = { __typename?: 'Query' } & {
  allCheckItems: Array<{ __typename?: 'CheckItem' } & CheckItemFieldsFragment>;
};

export type ClientsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ClientsByIdsQuery = { __typename?: 'Query' } & {
  clientsByIds: Array<{ __typename?: 'Client' } & ClientFieldsFragment>;
};

export type ClientsWithGenerationSiteNumByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ClientsWithGenerationSiteNumByIdsQuery = { __typename?: 'Query' } & {
  clientsByIds: Array<{ __typename?: 'Client' } & Pick<Client, 'generationSiteNum'> & ClientFieldsFragment>;
};

export type ClientsByKeywordsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: ClientsByKeywordsCondition;
}>;

export type ClientsByKeywordsQuery = { __typename?: 'Query' } & {
  clientsByKeywords: { __typename?: 'ClientsByKeywordsConnection' } & {
    edges: Array<
      { __typename?: 'ClientEdge' } & Pick<ClientEdge, 'cursor'> & {
          node: { __typename?: 'Client' } & ClientFieldsFragment;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type ClientsWithGenerationSiteNumByKeywordsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: ClientsByKeywordsCondition;
}>;

export type ClientsWithGenerationSiteNumByKeywordsQuery = { __typename?: 'Query' } & {
  clientsByKeywords: { __typename?: 'ClientsByKeywordsConnection' } & {
    edges: Array<
      { __typename?: 'ClientEdge' } & Pick<ClientEdge, 'cursor'> & {
          node: { __typename?: 'Client' } & Pick<Client, 'generationSiteNum'> & ClientFieldsFragment;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type AllCollectablePeriodTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCollectablePeriodTemplatesQuery = { __typename?: 'Query' } & {
  allCollectablePeriodTemplates: Array<
    { __typename?: 'CollectablePeriodTemplate' } & CollectablePeriodTemplateFieldsFragment
  >;
};

export type AllContainerTypeTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllContainerTypeTaskTypesQuery = { __typename?: 'Query' } & {
  allContainerTypeTaskTypes: Array<
    { __typename?: 'ContainerTypeTaskType' } & Pick<ContainerTypeTaskType, 'id'> & {
        containerType: { __typename?: 'ContainerType' } & ContainerTypeFieldsFragment;
        taskType: { __typename?: 'TaskType' } & TaskTypeFieldsFragment;
        values: { __typename?: 'ContainerTypeTaskTypeValue' } & {
          duration:
            | ({ __typename: 'IntegerConstValue' } & Pick<IntegerConstValue, 'value'>)
            | ({ __typename: 'IntegerOfficeDefaultValue' } & Pick<IntegerOfficeDefaultValue, 'value'>);
          status:
            | ({ __typename: 'ContainerTypeTaskTypeStatusConstValue' } & Pick<
                ContainerTypeTaskTypeStatusConstValue,
                'value'
              >)
            | ({ __typename: 'ContainerTypeTaskTypeStatusOfficeDefaultValue' } & Pick<
                ContainerTypeTaskTypeStatusOfficeDefaultValue,
                'value'
              >);
          isProportionalToCount:
            | ({ __typename: 'BooleanConstValue' } & Pick<BooleanConstValue, 'value'>)
            | ({ __typename: 'BooleanOfficeDefaultValue' } & Pick<BooleanOfficeDefaultValue, 'value'>);
        };
      }
  >;
};

export type AllContainerTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllContainerTypesQuery = { __typename?: 'Query' } & {
  allContainerTypes: Array<{ __typename?: 'ContainerType' } & ContainerTypeFieldsFragment>;
};

export type ContainerTypesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ContainerTypesByIdsQuery = { __typename?: 'Query' } & {
  containerTypesByIds: Array<{ __typename?: 'ContainerType' } & ContainerTypeFieldsFragment>;
};

export type DisposalSiteAttendancesByDateRangeQueryVariables = Exact<{
  condition: DisposalSiteAttendancesByDateRangeInput;
}>;

export type DisposalSiteAttendancesByDateRangeQuery = { __typename?: 'Query' } & {
  disposalSiteAttendancesByDateRange: Array<
    { __typename?: 'DisposalSiteAttendance' } & Pick<
      DisposalSiteAttendance,
      'id' | 'periodStart' | 'periodEnd' | 'restPeriodStart' | 'restPeriodEnd' | 'status' | 'acceptanceLimit' | 'note'
    > & {
        disposalSite: { __typename?: 'DisposalSite' } & DisposalSiteFieldsFragment;
        attendance: { __typename?: 'Attendance' } & AttendanceFieldsFragment;
      }
  >;
};

export type AllDisposalSitesQueryVariables = Exact<{ [key: string]: never }>;

export type AllDisposalSitesQuery = { __typename?: 'Query' } & {
  allDisposalSites: Array<{ __typename?: 'DisposalSite' } & DisposalSiteFieldsFragment>;
};

export type AllDriverAttendanceTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type AllDriverAttendanceTemplatesQuery = { __typename?: 'Query' } & {
  allDriverAttendanceTemplates: Array<
    { __typename?: 'DriverAttendanceTemplate' } & DriverAttendanceTemplateFieldsFragment
  >;
};

export type DriverAttendanceTemplatesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DriverAttendanceTemplatesByIdsQuery = { __typename?: 'Query' } & {
  driverAttendanceTemplatesByIds: Array<
    { __typename?: 'DriverAttendanceTemplate' } & DriverAttendanceTemplateFieldsFragment
  >;
};

export type AllDriverAttendancesByDateRangeQueryVariables = Exact<{
  condition: AllDriverAttendancesByDateRangeInput;
}>;

export type AllDriverAttendancesByDateRangeQuery = { __typename?: 'Query' } & {
  allDriverAttendancesByDateRange: Array<{ __typename?: 'DriverAttendance' } & DriverAttendanceFieldsFragment>;
};

export type DriverAttendancesByDateRangeQueryVariables = Exact<{
  condition: DriverAttendancesByDateRangeInput;
}>;

export type DriverAttendancesByDateRangeQuery = { __typename?: 'Query' } & {
  driverAttendancesByDateRange: Array<{ __typename?: 'DriverAttendance' } & DriverAttendanceFieldsFragment>;
};

export type AllDriversQueryVariables = Exact<{ [key: string]: never }>;

export type AllDriversQuery = { __typename?: 'Query' } & {
  allDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
};

export type DriversByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DriversByIdsQuery = { __typename?: 'Query' } & {
  driversByIds: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
};

export type AllDriversWithOptionalsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AllDriversWithOptionalsQuery = { __typename?: 'Query' } & {
  allDrivers: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
  driversByIds: Array<{ __typename?: 'Driver' } & DriverFieldsFragment>;
};

export type AllTransportationClientsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTransportationClientsQuery = { __typename?: 'Query' } & {
  allTransportationClients: Array<{ __typename?: 'ErpClient' } & Pick<ErpClient, 'id' | 'name'>>;
};

export type ErpOrderByOrderIdQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type ErpOrderByOrderIdQuery = { __typename?: 'Query' } & {
  erpOrderByOrderId: Maybe<
    { __typename: 'ErpOrder' } & Pick<ErpOrder, 'id' | 'orderId' | 'withDisposalItemFromScheduling' | 'note'> & {
        transportationClient: { __typename?: 'ErpClient' } & Pick<ErpClient, 'id' | 'name'>;
        erpOrderItems: Array<
          { __typename?: 'ErpOrderItem' } & Pick<
            ErpOrderItem,
            'id' | 'generationSiteTaskId' | 'irregularTaskId' | 'apparentQuantity' | 'apparentQuantityUnit'
          >
        >;
      }
  >;
};

export type GenerationSiteTasksByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GenerationSiteTasksByIdsQuery = { __typename?: 'Query' } & {
  generationSiteTasksByIds: Array<{ __typename?: 'GenerationSiteTask' } & GenerationSiteTaskFieldsFragment>;
};

export type GenerationSitesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GenerationSitesByIdsQuery = { __typename?: 'Query' } & {
  generationSitesByIds: Array<{ __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment>;
};

export type GenerationSitesByKeywordsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: GenerationSitesByKeywordsCondition;
}>;

export type GenerationSitesByKeywordsQuery = { __typename?: 'Query' } & {
  generationSitesByKeywords: { __typename?: 'GenerationSitesByKeywordsConnection' } & {
    edges: Array<
      { __typename?: 'GenerationSiteEdge' } & Pick<GenerationSiteEdge, 'cursor'> & {
          node: { __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type HolidayRuleQueryVariables = Exact<{ [key: string]: never }>;

export type HolidayRuleQuery = { __typename?: 'Query' } & {
  holidayRule: { __typename?: 'HolidayRule' } & Pick<
    HolidayRule,
    'id' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'nationalHoliday'
  >;
};

export type IrregularTasksByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type IrregularTasksByIdsQuery = { __typename?: 'Query' } & {
  irregularTasksByIds: Array<{ __typename?: 'IrregularTask' } & IrregularTaskFieldsFragment>;
};

export type AllJwnetWasteMastersQueryVariables = Exact<{ [key: string]: never }>;

export type AllJwnetWasteMastersQuery = { __typename?: 'Query' } & {
  allJwnetWasteMasters: Array<
    { __typename?: 'JwnetWasteMaster' } & Pick<
      JwnetWasteMaster,
      'id' | 'firstCategoryName' | 'secondCategoryName' | 'thirdCategoryName' | 'code' | 'isUsable'
    >
  >;
};

export type OfficeSettingQueryVariables = Exact<{ [key: string]: never }>;

export type OfficeSettingQuery = { __typename?: 'Query' } & {
  officeSetting: { __typename?: 'OfficeSetting' } & Pick<
    OfficeSetting,
    | 'id'
    | 'defaultDurationAtBaseSite'
    | 'defaultDurationAtGenerationSite'
    | 'defaultDurationAtDisposalSite'
    | 'defaultRestPeriodStartOfGenerationSite'
    | 'defaultRestPeriodEndOfGenerationSite'
    | 'defaultRestPeriodStartOfDisposalSite'
    | 'defaultRestPeriodEndOfDisposalSite'
    | 'defaultBannedDriverIdsAtGenerationSite'
    | 'defaultPreloadStatus'
    | 'defaultRouteCollectionAllowed'
    | 'defaultAvoidHighways'
    | 'isPackingPlacementManagementEnabled'
    | 'isFixedArrivalTimeReportEnabled'
    | 'defaultIsFixedArrivalTimeReportNeeded'
    | 'defaultMarginTypeOfFixedArrivalTime'
    | 'defaultMarginOfFixedArrivalTime'
    | 'isOnlineReservationEnabled'
    | 'isPublishingStatusOfScheduleManagementEnabled'
    | 'canPrintSchedules'
    | 'canUseErp'
    | 'useCsvImport'
    | 'useCsvExport'
  >;
};

export type OrderAcceptanceChecksByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type OrderAcceptanceChecksByIdsQuery = { __typename?: 'Query' } & {
  orderAcceptanceChecksByIds: Array<
    { __typename?: 'OrderAcceptanceCheck' } & Pick<
      OrderAcceptanceCheck,
      'id' | 'acceptanceData' | 'ordersNum' | 'estimatedFinishAt' | 'status'
    >
  >;
};

export type OrderDefaultByGenerationSiteIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderDefaultByGenerationSiteIdQuery = { __typename?: 'Query' } & {
  orderDefaultByGenerationSiteId: { __typename?: 'OrderDefault' } & Pick<
    OrderDefault,
    | 'defaultPreloadStatus'
    | 'defaultRouteCollectionAllowed'
    | 'defaultIsFixedArrivalTimeReportNeeded'
    | 'defaultMarginTypeOfFixedArrivalTime'
    | 'defaultMarginOfFixedArrivalTime'
  > & {
      defaultCollectablePeriodTemplate: {
        __typename?: 'CollectablePeriodTemplate';
      } & CollectablePeriodTemplateFieldsFragment;
    };
};

export type AllOrderGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllOrderGroupsQuery = { __typename?: 'Query' } & {
  allOrderGroups: Array<{ __typename?: 'OrderGroup' } & OrderGroupFieldsFragment>;
};

export type OrdersByDateQueryVariables = Exact<{
  date: Scalars['Iso8601Date'];
}>;

export type OrdersByDateQuery = { __typename?: 'Query' } & {
  ordersByDate: Array<{ __typename?: 'Order' } & OrderFieldsFragment>;
};

export type OrdersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type OrdersByIdsQuery = { __typename?: 'Query' } & {
  ordersByIds: Array<{ __typename?: 'Order' } & OrderFieldsFragment>;
};

export type LatestOrdersQueryVariables = Exact<{
  condition: LatestOrdersInput;
}>;

export type LatestOrdersQuery = { __typename?: 'Query' } & {
  latestOrders: Array<{ __typename?: 'Order' } & OrderFieldsFragment>;
};

export type SearchOrdersQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: SearchOrdersInput;
}>;

export type SearchOrdersQuery = { __typename?: 'Query' } & {
  searchOrders: { __typename?: 'SearchedOrdersConnection' } & {
    edges: Array<
      { __typename?: 'OrderEdge' } & Pick<OrderEdge, 'cursor'> & {
          node: { __typename?: 'Order' } & OrderFieldsFragment;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type RoutableOrdersQueryVariables = Exact<{
  condition: RoutableOrdersInput;
}>;

export type RoutableOrdersQuery = { __typename?: 'Query' } & {
  routableOrders: Array<{ __typename?: 'Order' } & OrderFieldsFragment>;
};

export type ValidateOrdersQueryVariables = Exact<{
  orders: Array<CreateOrderInput> | CreateOrderInput;
}>;

export type ValidateOrdersQuery = { __typename?: 'Query' } & Pick<Query, 'validateOrders'>;

export type PackingPlacementsByKeywordsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: PackingPlacementsByKeywordsCondition;
}>;

export type PackingPlacementsByKeywordsQuery = { __typename?: 'Query' } & {
  packingPlacementsByKeywords: { __typename?: 'PackingPlacementsByKeywordsConnection' } & {
    edges: Array<
      { __typename?: 'PackingPlacementsEdge' } & Pick<PackingPlacementsEdge, 'cursor'> & {
          node: { __typename: 'PackingPlacementsAtGenerationSite' } & Pick<
            PackingPlacementsAtGenerationSite,
            'lastAllocatedAt' | 'managementStartedOn'
          > & {
              generationSite: { __typename?: 'GenerationSite' } & GenerationSiteFieldsFragment;
              packingPlacements: Array<{ __typename?: 'PackingPlacement' } & PackingPlacementFieldsFragment>;
            };
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type AllPackingStyleTaskTypeDefaultsQueryVariables = Exact<{ [key: string]: never }>;

export type AllPackingStyleTaskTypeDefaultsQuery = { __typename?: 'Query' } & {
  allPackingStyleTaskTypeDefaults: Array<
    { __typename?: 'PackingStyleTaskTypeDefault' } & Pick<
      PackingStyleTaskTypeDefault,
      'id' | 'packingStyleId' | 'duration' | 'status' | 'isProportionalToCount'
    > & { taskType: { __typename?: 'TaskType' } & TaskTypeFieldsFragment }
  >;
};

export type AllPackingStylesQueryVariables = Exact<{ [key: string]: never }>;

export type AllPackingStylesQuery = { __typename?: 'Query' } & {
  allPackingStyles: Array<
    { __typename?: 'PackingStyle' } & Pick<PackingStyle, 'id' | 'code' | 'name' | 'defaultUnitName'>
  >;
};

export type ReservationsByDateQueryVariables = Exact<{
  date: Scalars['Iso8601Date'];
}>;

export type ReservationsByDateQuery = { __typename?: 'Query' } & {
  reservationsByDate: Array<{ __typename?: 'Reservation' } & ReservationFieldsFragment>;
};

export type CancelledReservationsByDateQueryVariables = Exact<{
  date: Scalars['Iso8601Date'];
}>;

export type CancelledReservationsByDateQuery = { __typename?: 'Query' } & {
  cancelledReservationsByDate: Array<{ __typename?: 'Reservation' } & ReservationFieldsFragment>;
};

export type WaitingReviewReservationDatesByDateRangeQueryVariables = Exact<{
  condition: WaitingReviewReservationDatesByDateRangeInput;
}>;

export type WaitingReviewReservationDatesByDateRangeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'waitingReviewReservationDatesByDateRange'
>;

export type ReservationDeadlineByDateQueryVariables = Exact<{
  date: Scalars['Iso8601Date'];
}>;

export type ReservationDeadlineByDateQuery = { __typename?: 'Query' } & {
  reservationDeadlineByDate: { __typename?: 'ReservationDeadline' } & Pick<
    ReservationDeadline,
    'reservable' | 'deadlineUpdatable'
  >;
};

export type AllRoutingRegulationsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRoutingRegulationsQuery = { __typename?: 'Query' } & {
  allRoutingRegulations: Array<{ __typename?: 'RoutingRegulationGenerationSite' } & RoutingRegulationFieldsFragment>;
};

export type RoutingRegulationsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RoutingRegulationsByIdsQuery = { __typename?: 'Query' } & {
  routingRegulationsByIds: Array<{ __typename?: 'RoutingRegulationGenerationSite' } & RoutingRegulationFieldsFragment>;
};

export type LatestSchedulesQueryVariables = Exact<{
  condition: LatestSchedulesInput;
}>;

export type LatestSchedulesQuery = { __typename?: 'Query' } & {
  latestSchedules: Array<
    { __typename?: 'Schedule' } & Pick<
      Schedule,
      | 'id'
      | 'attendanceId'
      | 'orderGroupId'
      | 'status'
      | 'creatingScheduleStartedAt'
      | 'scheduleResponse'
      | 'isPublished'
    >
  >;
};

export type SchedulesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SchedulesByIdsQuery = { __typename?: 'Query' } & {
  schedulesByIds: Array<
    { __typename?: 'Schedule' } & Pick<
      Schedule,
      | 'id'
      | 'attendanceId'
      | 'orderGroupId'
      | 'status'
      | 'creatingScheduleStartedAt'
      | 'scheduleResponse'
      | 'isPublished'
    >
  >;
};

export type ScheduleByScheduleRequestQueryVariables = Exact<{
  scheduleByScheduleRequestInput: ScheduleByScheduleRequestInput;
}>;

export type ScheduleByScheduleRequestQuery = { __typename?: 'Query' } & {
  scheduleByScheduleRequest: { __typename?: 'ScheduleByScheduleRequestResult' } & Pick<
    ScheduleByScheduleRequestResult,
    'requestId' | 'scheduleResponse'
  >;
};

export type RevertScheduleQueryVariables = Exact<{
  revertScheduleInput: RevertScheduleInput;
}>;

export type RevertScheduleQuery = { __typename?: 'Query' } & {
  revertSchedule: { __typename?: 'RevertScheduleResult' } & Pick<
    RevertScheduleResult,
    'requestId' | 'affectedDriverIds' | 'scheduleResponse'
  >;
};

export type CurrentSessionQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentSessionQuery = { __typename?: 'Query' } & {
  currentSession: { __typename?: 'Session' } & Pick<Session, 'officeId' | 'officeName'> & {
      user: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    };
};

export type AllTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllTaskTypesQuery = { __typename?: 'Query' } & {
  allTaskTypes: Array<{ __typename: 'TaskType' } & TaskTypeFieldsFragment>;
};

export type UsersQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Maybe<Scalars['String']>;
  condition: UsersCondition;
}>;

export type UsersQuery = { __typename?: 'Query' } & {
  users: { __typename?: 'UsersConnection' } & {
    edges: Array<
      { __typename?: 'UserEdge' } & Pick<UserEdge, 'cursor'> & {
          node: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export type UsersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UsersByIdsQuery = { __typename?: 'Query' } & {
  usersByIds: Array<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>;
};

export type AllWasteTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllWasteTypesQuery = { __typename?: 'Query' } & {
  allWasteTypes: Array<{ __typename?: 'WasteType' } & WasteTypeFieldsFragment>;
};

export type AllDisplayedWasteTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllDisplayedWasteTypesQuery = { __typename?: 'Query' } & {
  allDisplayedWasteTypes: Array<{ __typename?: 'WasteType' } & WasteTypeFieldsFragment>;
};

export type WasteTypesByKeywordsQueryVariables = Exact<{
  keywords: Array<Scalars['String']> | Scalars['String'];
}>;

export type WasteTypesByKeywordsQuery = { __typename?: 'Query' } & {
  wasteTypesByKeywords: Array<{ __typename?: 'WasteType' } & WasteTypeFieldsFragment>;
};

export type AvailableWasteTypeCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type AvailableWasteTypeCodeQuery = { __typename?: 'Query' } & Pick<Query, 'availableWasteTypeCode'>;
