/**
 * Api がそのまま GraphQL の型に依存しないためにひとまずエイリアスを張っておく。
 * 本当はここに定義されているものを個別の adapter が実装するという形が正しいが、
 * 現実的に GraphQL の Api から出力された型の方が正確なのでこういう形になっている。
 * 必要があればここで export しておき、Application Service などが直接 @/graphql/graphQLServerApi を参照しない様にする。
 *
 * domain 層のものが参照しなければいけないものは別途 domain 以下に typeAliases.ts を作成している。
 * Clean Architecture に則るのであれば、domain <- server-api <- adapter の依存関係になるべきなので。
 */

export {
  ClientsByKeywordsOrder,
  GenerationSitesByKeywordsOrder,
  PackingPlacementsByKeywordsOrder,
  PublishingStatus,
  DisposalSiteAttendanceStatus,
} from '@/graphql/graphQLServerApi';

export type {
  CreateIrregularTaskInput,
  CreateOrderRecurringSettingsInput,
  CreateOrderDisposalSitesAndTypeInput,
  CreateOrderDisposalSiteInput,
  CreateOrderAssignableDriversAndNumInput,
  OrderRecurringSettings,
  CreateGenerationSiteTaskInput,
  RoutableOrdersInput,
  OrderRoutingGroupInput,
  ClientsByKeywordsCondition,
  GenerationSitesByKeywordsCondition,
  CreateOrderInput,
  UpdateOrderInput,
  OrderPlanInput,
  OrderCandidateDateInput,
  OrderPlan,
  Order,
  OrderRoutingGroup,
  ClientPackingStyleReservationSettingInput,
  AcceptReservationInput,
  OrderCheckItem,
  CreateDefaultMultipleDriversAttendancesPayload,
  UsersCondition,
} from '@/graphql/graphQLServerApi';
