import * as Search from './search';
import { Id, Maybe } from '~/framework/typeAliases';
import {
  ICondition,
  packingPlacement$getByKeywordsSymbol,
} from '~/framework/server-api/packing-placement/getByKeywords';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { mapEntity } from '~/framework/core/mapper';
import { PackingPlacementMapper } from '~/framework/domain/packing-placement/packing-placement/packingPlacementMapper';
import {
  IUpdatePackingPlacementData,
  packingPlacement$updateSymbol,
} from '~/framework/server-api/packing-placement/update';
import { PackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/packingPlacementEntity';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';

export const packingPlacementSymbol = Symbol('packingPlacement');

export class PackingPlacementApplicationService implements Search.ISearch {
  private readonly serverApis: ServerApiManager;
  private packingPlacementMapper: PackingPlacementMapper;
  private generationSiteMapper: GenerationSiteMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.packingPlacementMapper = new PackingPlacementMapper(
      store.packingPlacement.packingPlacement,
      store.masters.containerType,
      store.masters.packingStyle
    );
    this.generationSiteMapper = new GenerationSiteMapper(
      store.masters.generationSite,
      store.masters.client,
      store.masters.user,
      store.masters.disposalSite,
      store.masters.collectablePeriodTemplate,
      store.masters.car,
      store.masters.baseSite,
      store.masters.driver,
      store.masters.driverAttendanceTemplate
    );
  }

  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<Search.IList> {
    const packingPlacement$getByKeywordsApi = this.serverApis.get(packingPlacement$getByKeywordsSymbol);
    const result = await packingPlacement$getByKeywordsApi.getByKeywords(first, after, condition);

    // 現状、排出場以外である事は想定しない
    const generationSiteEdges = result.edges.filter(
      (edge) => edge.node.__typename === 'PackingPlacementsAtGenerationSite'
    );
    const generationSiteDatas = generationSiteEdges.map((edge) => edge.node.generationSite);
    const generationSites = this.generationSiteMapper.map(generationSiteDatas);
    const generationSiteMap = mapEntity(generationSites);

    const output: Search.IList = {
      pageInfo: result.pageInfo,
      items: generationSiteEdges.map((edge) => {
        return {
          __typename: 'PackingPlacementsAtGenerationSite',
          generationSite: generationSiteMap.getOrError(edge.node.generationSite.id),
          cursor: edge.cursor,
          packingPlacements: this.packingPlacementMapper.map(
            edge.node.packingPlacements.map((aData) => {
              return {
                containerTypeId: aData.containerType.id,
                ...aData,
              };
            })
          ),
          lastAllocatedAt: edge.node.lastAllocatedAt,
          managementStartedOn: edge.node.managementStartedOn,
        };
      }),
    };
    return output;
  }

  async update(
    generationSiteId: Id,
    packingPlacements: IUpdatePackingPlacementData[]
  ): Promise<PackingPlacementEntity[]> {
    const packingPlacement$updateApi = this.serverApis.get(packingPlacement$updateSymbol);
    const result = await packingPlacement$updateApi.update(generationSiteId, packingPlacements);
    const entities = this.packingPlacementMapper.map(
      result.map((aData) => {
        return {
          containerTypeId: aData.containerType.id,
          ...aData,
        };
      })
    );
    return entities;
  }
}
