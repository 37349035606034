import { Location } from 'vue-router';
import { Path } from '~/framework/constants';
import { formatDateToString } from '~/framework/services/date/date';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { SearchOrdersInput } from '~/graphql/graphQLServerApi';

/**
 * URL の query parameter から獲得する受注検索の条件の型。
 * Value が string になるように Date や boolean を string として型を定義。
 */
export type SearchOrdersQueryParameters = {
  // プロパティがなくても大丈夫ように `?` を使う。

  clientId?: Maybe<PersistentId>;
  generationSiteId?: Maybe<PersistentId>;
  /** YYYY-MM-DD */
  startAt?: Maybe<string>;
  endAt?: Maybe<string>;
  createdStartAt?: Maybe<string>;
  createdEndAt?: Maybe<string>;
  createdById?: Maybe<PersistentId>;
  note?: Maybe<string>;
  // wasteTypeId は機能追加時に足す
  includeCancelled?: 'true' | 'false';
  includeFollowingRecurringOrder?: 'true' | 'false';
  onlyFixedDisplayOnReservation?: 'true' | 'false';
};

/**
 * 受注検索に使う条件の型。
 * wasteTypeId は SearchOrdersInput にあるが、ここでは省略。
 */
export type SearchOrdersConditions = Omit<Partial<SearchOrdersInput>, 'wasteTypeId'>;

export const SearchOrdersConditionDefault: SearchOrdersInput = {
  clientId: undefined,
  generationSiteIds: [],
  startAt: undefined,
  endAt: undefined,
  createdStartAt: undefined,
  createdEndAt: undefined,
  note: undefined,
  wasteTypeId: undefined,
  createdById: undefined,
  includeCancelled: false,
  includeFollowingRecurringOrder: false,
  onlyFixedDisplayOnReservation: false,
};

export const getRouteLocationForSearchOrdersPage = (conditions?: Maybe<SearchOrdersConditions>): Location => {
  const path = Path.schedule.orders.index;

  const startAt = conditions?.startAt ? formatDateToString(conditions?.startAt) : undefined;
  const endAt = conditions?.endAt ? formatDateToString(conditions?.endAt) : undefined;
  const createdStartAt = conditions?.createdStartAt ? formatDateToString(conditions?.createdStartAt) : undefined;
  const createdEndAt = conditions?.createdEndAt ? formatDateToString(conditions?.createdEndAt) : undefined;
  const createdById = conditions?.createdById;
  const note = conditions?.note;
  const includeCancelled = conditions?.includeCancelled?.toString() as SearchOrdersQueryParameters['includeCancelled'];
  const includeFollowingRecurringOrder =
    conditions?.includeFollowingRecurringOrder?.toString() as SearchOrdersQueryParameters['includeFollowingRecurringOrder'];
  const onlyFixedDisplayOnReservation =
    conditions?.onlyFixedDisplayOnReservation?.toString() as SearchOrdersQueryParameters['onlyFixedDisplayOnReservation'];
  const query: SearchOrdersQueryParameters = {
    clientId: conditions?.clientId,
    generationSiteId: conditions?.generationSiteIds?.[0],
    startAt,
    endAt,
    createdStartAt,
    createdEndAt,
    createdById,
    note,
    includeCancelled,
    includeFollowingRecurringOrder,
    onlyFixedDisplayOnReservation,
  };

  return { path, query };
};
