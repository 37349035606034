import { IOrderData } from '~/framework/server-api/schedule/order/order';
import { SearchOrdersInput } from '~/graphql/graphQLServerApi';
import { IConnection } from '~/framework/server-api/connection';
import { Maybe } from '~/framework/typeAliases';

export const order$searchOrders = Symbol('order$searchOrders');

export interface ISearchOrdersApi {
  [order$searchOrders]: void;
  searchOrders(first: number, after: Maybe<string>, condition: SearchOrdersInput): Promise<IConnection<IOrderData>>;
}
